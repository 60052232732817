import React, { SetStateAction, useRef, useContext, useState, useEffect } from "react";
import { ACTIVATE_MENU, DEACTIVATE_MENU, DELETE_WARNING, MENU_DELETE_WARNING, ACTIVATE, DEACTIVATE, EDIT, DELETE, SCHEDULE } from "../../../../constants/strings";
import { checkPermission } from "../../../../helpers/utils";
import { CommonCtx } from "../../../../context/CommonCtxProvider";
import { CSS } from '@dnd-kit/utilities';
import { FaEdit, FaToggleOff, FaToggleOn, FaRegClock } from "react-icons/fa";
import { FnBCtx } from "../../../../context/MoveItemCtxProvider";
import { Menu, MenuItem, SubCategory } from "../../../../constants/staticTypes";
import { ADMIN_SELECTED_MENU, PermissionKeys, SubPermissionKeys, WarningCallback } from "../../../../constants/constants";
import { QueryClient } from "react-query";
import { useSortable } from "@dnd-kit/sortable";
import placeholderImage from "../../../../assets/images/menu/540x360.png";
import { TranslationContext, jTranslationText } from "../../../../helpers/jTranslate";
import MlxTooltip from "../../../common/MlxTooltip";

type Props = {
    setEditItem: React.Dispatch<React.SetStateAction<Menu | SubCategory | MenuItem | undefined>>,
    setAddMenu: React.Dispatch<React.SetStateAction<boolean>>,
    showAddMenu: boolean,
    selectedMenu: Menu | undefined,
    setSelectedMenu: React.Dispatch<React.SetStateAction<Menu | undefined>>,
    setSelectedCategory: React.Dispatch<React.SetStateAction<SubCategory | undefined>>,
    setDeleteItem: React.Dispatch<React.SetStateAction<string | undefined>>,
    setSelectedItem: React.Dispatch<React.SetStateAction<MenuItem | undefined>>,
    setWarningMessage: React.Dispatch<React.SetStateAction<string>>,
    setWarning: React.Dispatch<React.SetStateAction<boolean>>,
    setCallbackType: React.Dispatch<React.SetStateAction<WarningCallback | undefined>>,
    onActiveInactiveToggle: (id: string, message: string, callbackType: SetStateAction<WarningCallback | undefined>) => void,
    menu: Menu,
    queryClient: QueryClient
}

function MenuComponent({ selectedMenu, menu, setSelectedMenu, setSelectedCategory, setSelectedItem, setEditItem, queryClient,
    setAddMenu, setCallbackType, setWarningMessage, setDeleteItem, setWarning, showAddMenu, onActiveInactiveToggle }: Props) {
    // CONTEXT VARIABLES
    const { setScheduleMenu } = useContext(CommonCtx)
    const { setScheduleMenuItem } = useContext(FnBCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition } = useSortable({ id: menu.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "pan-y"
    };

    const itemCard = useRef(null)

    const [translationText, setTranslatedText] = useState<{
        deActivate: string,
        activate: string,
        edit: string,
        schedule: string,
        delete: string,
      }>({ 
        deActivate: DEACTIVATE,
        activate: ACTIVATE,
        edit: EDIT,
        schedule: SCHEDULE,
        delete: DELETE,
    });

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: DEACTIVATE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: ACTIVATE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: SCHEDULE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    deActivate: translations[0] ?? DEACTIVATE,
                    activate: translations[1] ?? ACTIVATE,
                    edit: translations[2] ?? EDIT,
                    schedule: translations[3] ?? SCHEDULE,
                    delete: translations[4] ?? DELETE,
                })
            } catch {
                setTranslatedText({
                    deActivate: DEACTIVATE,
                    activate: ACTIVATE,
                    edit: EDIT,
                    schedule: SCHEDULE,
                    delete: DELETE,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    return <div key={menu.id} ref={setNodeRef} style={style} {...attributes} {...listeners} id={"scroll#" + menu.id}
        className={"viewMenubox-container menu-new-height " + (selectedMenu?.id === menu.id && " active ") +
            (!menu.isActive && " in-active")}
        data-testid="menu-card"
        onClick={() => {
            if (selectedMenu !== menu) {
                setSelectedMenu(menu);
                setSelectedCategory(menu.subCategory[0]);
                setSelectedItem(menu.subCategory[0]?.menuItems[0]);
                localStorage.setItem(ADMIN_SELECTED_MENU, JSON.stringify(menu));
            }
        }}>
        <div
            className="menubox-img-contanier"
            ref={itemCard}
        // onMouseEnter={() => {
        //     // @ts-ignore
        //     itemCard.current.click()
        // }}
        >
            <img src={menu?.photoThumbnails || placeholderImage} 
                onError={(event)=>{event.currentTarget.src = menu.signedUrl}}
                alt="menu item" 
                className="" 
            />
            <MlxTooltip target=".menu-title">
                <div className="menu-title position-btm" data-pr-tooltip={menu.categoryName} data-pr-position="mouse">
                    {menu.categoryName}
                </div>
            </MlxTooltip>
            {/*  <div className="overlay"></div> */}
        </div>

        <div className="menubox-toolbox tools">
            {menu.isActive ?
                <FaToggleOn
                    title={translationText.deActivate}
                    data-testid="menu-toggle-on"
                    className={"view " +
                        (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS) &&
                            " disabled")} onClick={() => {
                                if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS)) {
                                    onActiveInactiveToggle(menu.id, DEACTIVATE_MENU, WarningCallback.ACTIVATE_DEACTIVATE_MENU);
                                }
                            }} /> :
                <FaToggleOff
                    title={translationText.activate}
                    data-testid="menu-toggle-off"
                    className={"view " +
                        (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS) &&
                            " disabled")} onClick={() => {
                                if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS)) {
                                    onActiveInactiveToggle(menu.id, ACTIVATE_MENU, WarningCallback.ACTIVATE_DEACTIVATE_MENU);
                                }
                            }} />}
            <i
                title={translationText.edit}
                data-testid="menu-edit"
                className={"ri-pencil-line edit " +
                    (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.EDIT) &&
                        " disabled")}  onClick={() => {
                            if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.EDIT)) {
                                setEditItem(menu);
                                setAddMenu(!showAddMenu);
                            }
                        }}></i>
            <i title={translationText.schedule}
                data-testid="menu-schedule"
                className={"ri-time-line schedule " +
                    (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.MANAGE_SCHEDULE) &&
                        " disabled")} onClick={() => {
                            if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.MANAGE_SCHEDULE)) {
                                setScheduleMenuItem({ id: menu.id, menuName: menu.categoryName });
                                setScheduleMenu(true);
                            }
                        }}></i> 
            <i title={translationText.delete} data-testid="menu-delete" className={"ri-delete-bin-line delete " +
                (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.DELETE) &&
                    " disabled")} onClick={() => {
                        if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.DELETE)) {
                            setCallbackType(WarningCallback.DEL_CATEGORY);
                            setDeleteItem(menu.id);
                            if (menu.subCategory.length > 0) {
                                setWarningMessage(MENU_DELETE_WARNING);
                            } else {
                                setWarningMessage(DELETE_WARNING);
                            }
                            setWarning(true);
                        }
                    }}></i>
        </div>
    </div>
}

export default MenuComponent;