import { SetStateAction, useContext, useEffect, useState } from "react";
import { ACTIVATE_CATEGORY, CATEGORY_DELETE_WARNING, DEACTIVATE_CATEGORY, DELETE_WARNING, ACTIVATE, DEACTIVATE, EDIT, DELETE, MOVE, COPY } from "../../../../constants/strings";
import { checkPermission } from "../../../../helpers/utils";
import { CSS } from '@dnd-kit/utilities';
import { FaEdit, FaToggleOff, FaToggleOn } from "react-icons/fa";
import { Menu, MenuItem, SubCategory } from "../../../../constants/staticTypes";
import { FnBCtx } from '../../../../context/MoveItemCtxProvider';
import { ADMIN_SELECTED_CATEGORY, PermissionKeys, SubPermissionKeys, WarningCallback } from "../../../../constants/constants";
import { QueryClient } from "react-query";
import { useSortable } from "@dnd-kit/sortable";
import Dropdown from 'react-bootstrap/Dropdown';
import { JTranslation, TranslationContext, jTranslationText } from "../../../../helpers/jTranslate";
import MlxTooltip from "../../../common/MlxTooltip";

type Props = {
    setEditItem: React.Dispatch<React.SetStateAction<Menu | SubCategory | MenuItem | undefined>>,
    selectedMenu: Menu | undefined,
    setSelectedCategory: React.Dispatch<React.SetStateAction<SubCategory | undefined>>,
    setDeleteItem: React.Dispatch<React.SetStateAction<string | undefined>>,
    setSelectedItem: React.Dispatch<React.SetStateAction<MenuItem | undefined>>,
    setWarningMessage: React.Dispatch<React.SetStateAction<string>>,
    setWarning: React.Dispatch<React.SetStateAction<boolean>>,
    setCallbackType: React.Dispatch<React.SetStateAction<WarningCallback | undefined>>,
    onActiveInactiveToggle: (id: string, message: string, callbackType: SetStateAction<WarningCallback | undefined>) => void,
    selectedCategory: SubCategory | undefined,
    setAddCatgory: React.Dispatch<React.SetStateAction<boolean>>,
    showAddCategory: boolean,
    category: SubCategory,
    queryClient: QueryClient
}

function CategoryComponent({ setEditItem, category, selectedCategory, setSelectedCategory, setAddCatgory, showAddCategory,
    setDeleteItem, queryClient, setSelectedItem, setWarningMessage, setWarning, setCallbackType, onActiveInactiveToggle }: Props) {
    // CONTEXT VARIABLE
    const { moveItemSideBar, setMoveItemSideBar, setMoveType, setCategoryToBeMoved, setCategoryToBeMovedDetails } = useContext(FnBCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition } = useSortable({ id: category.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "pan-y"
    };

    const [translationText, setTranslatedText] = useState<{
        deActivate: string,
        activate: string,
        edit: string,
        delete: string,
      }>({ 
        deActivate: DEACTIVATE,
        activate: ACTIVATE,
        edit: EDIT,
        delete: DELETE,
    });

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: DEACTIVATE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: ACTIVATE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    deActivate: translations[0] ?? DEACTIVATE,
                    activate: translations[1] ?? ACTIVATE,
                    edit: translations[2] ?? EDIT,
                    delete: translations[3] ?? DELETE,
                })
            } catch {
                setTranslatedText({
                    deActivate: DEACTIVATE,
                    activate: ACTIVATE,
                    edit: EDIT,
                    delete: DELETE,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    return <div key={category.id} ref={setNodeRef} style={style} {...attributes} {...listeners}
        className={"viewMenubox-container custom-height " + (selectedCategory?.id === category.id && "active ") +

            (!category.isActive && " in-active")}
        data-testid="category-card"
        onClick={() => {
            setSelectedCategory(category)
            setSelectedItem(category?.menuItems[0]);
            localStorage.setItem(ADMIN_SELECTED_CATEGORY, JSON.stringify(category))
        }}>

        <div className="menubox-img-contanier category custom-height">
            <Dropdown className="move-btn-outer">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <span className="move-round  d-flex align-items-center justify-content-center bg-white rounded-circle shadow link-secondary">
                        <i className="ri-more-2-fill"></i>
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item className=" d-flex move-list" href="#/action-1" data-testid="move-to"
                        onClick={() => {
                            setMoveType("category")
                            setCategoryToBeMoved(category.id)
                            setCategoryToBeMovedDetails(category)
                            setMoveItemSideBar(!moveItemSideBar)
                        }}
                    ><i className="ri-drag-move-2-line"></i><JTranslation typeCase="pascal" text={MOVE} />
                    </Dropdown.Item>
                    <Dropdown.Item className=" d-flex move-list" href="#/action-1" data-testid="copy-to"
                        onClick={() => {
                            setMoveType("copy-category-to")
                            setCategoryToBeMoved(category.id)
                            setCategoryToBeMovedDetails(category)
                            setMoveItemSideBar(!moveItemSideBar)
                        }}
                    ><i className="ri-drag-move-2-line"></i><JTranslation typeCase="pascal" text={COPY} />
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <MlxTooltip target=".menu-title">
            <div className="menu-title" data-pr-tooltip={category.categoryName} data-pr-position="bottom">
                {category.categoryName}
            </div>
            </MlxTooltip>
        </div>
        <div className="menubox-toolbox tools">
            {category.isActive ?
                <FaToggleOn title={translationText.deActivate} data-testid="category-toggle-on" className={"view " +
                    (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS) &&
                        " disabled")} onClick={() => {
                            if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS)) {
                                onActiveInactiveToggle(category.id, DEACTIVATE_CATEGORY, WarningCallback.ACTIVATE_DEACTIVATE_MENU);
                            }
                        }} /> :
                <FaToggleOff title={translationText.activate} data-testid="category-toggle-off" className={"view " +
                    (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS) &&
                        " disabled")} onClick={() => {
                            if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS)) {
                                onActiveInactiveToggle(category.id, ACTIVATE_CATEGORY, WarningCallback.ACTIVATE_DEACTIVATE_MENU);
                            }
                        }} />}
            <i title={translationText.edit} data-testid="category-edit" className={"ri-pencil-line edit " +
                (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.EDIT) &&
                    " disabled")} onClick={() => {
                        if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.EDIT)) {
                            setEditItem(category);
                            setAddCatgory(!showAddCategory);
                        }
                    }}> </i>
            <i title={translationText.delete} data-testid="category-delete" className={"ri-delete-bin-line delete " +
                (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.DELETE) &&
                    " disabled")} onClick={() => {
                        if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.DELETE)) {
                            setCallbackType(WarningCallback.DEL_CATEGORY);
                            setDeleteItem(category.id);
                            if (category.menuItems.length > 0) {
                                setWarningMessage(CATEGORY_DELETE_WARNING);
                            } else {
                                setWarningMessage(DELETE_WARNING);
                            }
                            setWarning(true);
                        }
                    }}></i>
        </div>
    </div>
}

export default CategoryComponent;