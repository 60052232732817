import { useState, useContext, useEffect } from "react";
import { AxiosResponse } from "axios";
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { CommonCtx } from "../../context/CommonCtxProvider";
import { Coupon, Users } from "../../constants/staticTypes";
import { Dropdown } from "react-bootstrap";
import { getCouponDetails, redeemCoupon } from "../../helpers/manageCouponsHelper";
import { GUEST_LOCATIONS, AlertVariant, SPECIAL_MENU_LOCATIONS, SPECIAL_MENU_CODE, SELECTED_MENU, USER_NAME } from "../../constants/constants";
import { initialPosValue } from "../../context/defaultContextValues";
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate';
import { LOGIN_STATUS, USER_INFO, SELECT_MENU_ITEM } from "../../constants/queryKeys";
import { QueryClient, useMutation } from "react-query";
import { routes } from "../../constants/routes";
import { saveMenuSelection } from "../../helpers/menuViewHelper";
import { showDashboard, showLoader, logoutActions } from "../../helpers/utils";
import { useIdle } from 'react-use';
import { useLocation, useNavigate } from "react-router-dom";
import { userLogout } from "../../helpers/authHelper";
import CommonModal from "../common_modal/CommonModal";
import placeholder from "../../assets/images/placeholder-avatar.jpg";
import QRScanner from "../manage_coupon/QRScanner";
import ToastAlert from "../alert/ToastAlert";
import useMutationHook from "../../hooks/useMutationHook";
import { useTrackEvent } from "../../hooks/useTrackEvent";

import {
  ACTIVE,
  COUPON_VALIDITY,
  DESCRIPTION,
  QR_CODE_SCANNER,
  REDEEM,
  STATUS,
  VOUCHER_CODE_AUTH_REQUIRED,
  VOUCHER_CODE,
  VOUCHER_DETAILS_ERROR,
  CHANNEL,
  FORCE_LOGOUT,
} from '../../constants/strings';
import useVisibility from "../../hooks/UseVisibility";
import { useTranslation } from "../../hooks/useTranslation";


type Props = {
    queryClient: QueryClient
}

function HeaderDropDown({ queryClient }: Props) {
    const { trackEvent } = useTrackEvent()
    const navigate = useNavigate();
    const location = useLocation();
    const loginStatus = queryClient.getQueryData(LOGIN_STATUS) as { isLoggedIn: boolean };
    const userInfo = queryClient.getQueryData(USER_INFO) as AxiosResponse<any, any>;
    const user = userInfo?.data?.data as Users;
    const couponMutation = useMutationHook(queryClient, true);
    const menuCode = localStorage.getItem(SPECIAL_MENU_CODE)

    // CONTEXT VARIABLES
    const { setPosUserInfo, setIsUserPos, isUserPos, posUserInfo, guestFnbGlobalSettings } = useContext(CommonCtx)
    const translationContext = useContext(TranslationContext)
    const { changeTargetLanguage } = useContext(TranslationContext)

    // STATE VARIABLES
    const [showModal, setShowModal] = useState(false);
    const [showScanner, setScanner] = useState(true);
    const [voucherCode, setVoucherCode] = useState("");
    const [voucherDetails, setVoucherDetails] = useState<Coupon | null>(null);
    const [profileImage, setProfileImage] = useState(placeholder);

    // TOAST VARIABLES
    const [showToast, setToast] = useState(false); // Toast alert state
    const [toastMessage, setToastMessage] = useState(""); // Toast message
    const [toastVariant, setToastVariant] = useState(""); // Toast Variant

    // Idle timeout
    const idleTimeLimit = guestFnbGlobalSettings.menuResetTimeInMinutes > 0 ? guestFnbGlobalSettings.menuResetTimeInMinutes : 10 // minutes
    const isIdle = useIdle(60000 * idleTimeLimit);
    const isTabVisible = useVisibility();


    // on scanner close
    const cleanUpScannedData = () => {
        setShowModal(false)
        setVoucherCode("")
        setVoucherDetails(null)
    }

    // on redeem success
    const onRedeemSuccess = (oldItem: Coupon, newItem: Coupon, message: string) => {
      showToastMessage(message, AlertVariant.SUCCESS)
    }

    // show toast
    const onCouponDetailsError = (message: string, variant: string) => {
        setToastMessage(VOUCHER_DETAILS_ERROR)
        setToastVariant(variant);
        setToast(true);
        cleanUpScannedData()
    }

    // show toast
    const displayToast = (message: string, variant: string) => {
        setToastMessage(message)
        setToastVariant(variant);
        setToast(true);
    }

    // on redeem error
    const showToastMessage = (message: string, variant: string) => {
        setToastMessage(message)
        setToastVariant(variant);
        setToast(true);
        cleanUpScannedData()
    }

    const onSuccess = (coupon: Coupon, message: string) => {
      setVoucherDetails(coupon)
    }

    const isStringIsUrl = (scannedText: string): boolean => {
      try {
        new URL(scannedText)
        return true
      } catch (error) {
        return false
      }
    }

    const setScanResult = (scannedText: string) => {

      if (isStringIsUrl(scannedText)) {
        try {
          const scannedURL = new URL(scannedText)
          const scannedDomain = scannedURL.hostname

          // Get the current domain
          const currentURL = window.location.href
          const currentDomain = new URL(currentURL).hostname
          // Compare the domains and navigate accordingly
          if (currentDomain === scannedDomain) {
            // Go to the scanned URL in the same tab
            setShowModal(false)
            window.location.href = scannedText
          }
        } catch (error) {
          // Handle any URL parsing errors or other exceptions
          console.error('Invalid URL:', error)
          setToastMessage('Invalid URL')
          setToastVariant(AlertVariant.ERROR)
          setToast(true)
          setShowModal(false)
        }
      }else{

        if (scannedText) {
          if (loginStatus?.isLoggedIn){
          setVoucherCode(scannedText)
          // api call to get the details of the scanned code
          getCouponDetails(
            queryClient,
            scannedText,
            couponMutation,
            onSuccess,
            onCouponDetailsError
          )
          }else{
            setToastMessage(VOUCHER_CODE_AUTH_REQUIRED)
            setToastVariant(AlertVariant.ERROR)
            setToast(true)
            setShowModal(false)
          }
        }
        }
    }

    // qr code modal content
    const modalContent = (
      <>
        {showScanner && (
          <QRScanner
            setScanResult={setScanResult}
            setScanner={setShowModal}
            showToastMessage={displayToast}
            hideBackButton={true}
            showScanner={showModal}
          />
        )}
        {couponMutation.isLoading && (
          <div style={{height: '20rem'}}></div>
        )}
        {voucherDetails && (
          <Card title='Voucher Status' className='qr-satus  shadow-custom'>
            <div className='row mb-2'>
              <div className='col-5 fw-bold d-flex justify-content-between'>
                <JTranslation typeCase="pascal" text={VOUCHER_CODE} /> <span>:</span>
              </div>
              <div className='col-7'>{voucherDetails?.couponCode}</div>
            </div>

            <div className='row mb-2'>
              <div className='col-5 fw-bold d-flex justify-content-between'>
                <JTranslation typeCase="pascal" text={STATUS} /> <span>:</span>
              </div>
              <div className='col-7'>
                {voucherDetails?.couponStatus === ACTIVE ? (
                  <Badge
                    className='custom-badge'
                    severity='success'
                    value={voucherDetails.couponStatus}
                  />
                ) : (
                  <Badge
                    className='custom-badge'
                    severity='danger'
                    value={voucherDetails.couponStatus}
                  />
                )}
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-5 fw-bold d-flex justify-content-between'>
                <JTranslation typeCase="pascal" text={DESCRIPTION} /> <span>:</span>
              </div>
              <div className='col-7'>
                {voucherDetails?.gamePrizeDetails.prizeWinning}
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-5 fw-bold d-flex justify-content-between'>
                <JTranslation typeCase="pascal" text={COUPON_VALIDITY} /> <span>:</span>
              </div>
              <div className='col-7'>
                {voucherDetails?.couponValidTillFormatted}
              </div>
            </div>

            <div className='mt-4  d-flex justify-content-center'>
              {voucherDetails?.couponStatus === ACTIVE ? (
                <button
                  type='button'
                  className='btn btn-custom-primary'
                  onClick={() => {
                    // api call to redeem the voucher
                    redeemCoupon(
                      queryClient,
                      voucherDetails,
                      couponMutation,
                      onRedeemSuccess,
                      showToastMessage
                    )
                  }}
                >
                  <JTranslation typeCase="pascal" text={REDEEM} />
                </button>
              ) : null}
            </div>
          </Card>
        )}
      </>
    )

    const commonModalHideAction = () => cleanUpScannedData()

    // handle user logout click using react query
    const userLogoutMutation = useMutation(userLogout, {
        onMutate: () => {
            showLoader(queryClient, true)
        },
        onSuccess: () => {  // logout success
          // logout success
          logoutActions({ queryClient })
          setPosUserInfo(initialPosValue)
          setIsUserPos(false)
          changeTargetLanguage('en')
          localStorage.setItem('targetLanguage', 'en')
          localStorage.setItem('languageChangeTime', new Date().getTime().toString())
          // Remove the 'qr_scan' flag from local storage to enable the script for showing or hiding schedule items.
          localStorage.removeItem('qr_scan')

          // create a new broadcast channel with the same name
          const channel = new BroadcastChannel(CHANNEL)
          channel.postMessage(FORCE_LOGOUT)
          channel.close()

          navigate(routes.welcome_page)
        },
        onSettled: () => { showLoader(queryClient, false) }
    });

    const getUserProfilePic = () => {
        if (isUserPos) {
            return posUserInfo.signedUrl ? posUserInfo.signedUrl : placeholder
        } else if (user?.signedUrl) {
            return user.signedUrl ? user.signedUrl : placeholder
        } else {
            return placeholder
        }
    }

    const dataCleanUp = () => {
		// removing any previously selected menu item for React Query and local storage
		localStorage.removeItem(SELECTED_MENU)
		localStorage.removeItem(SPECIAL_MENU_CODE)
		queryClient.removeQueries(SELECT_MENU_ITEM)
	}

    // effect to close the qr code scanner
    useEffect(() => { if (voucherCode) setScanner(false) }, [voucherCode])

    // effect to logout the user once the ideal time limit is over
    // useEffect(() => {
    //     if (isIdle && isUserPos) userLogoutMutation.mutate()
    // }, [isIdle, isUserPos]) // eslint-disable-line

    // effect to logout the user once the ideal time limit is over and redirect to welcome page
    useEffect(() => {
      // Skip welcome page from guest routes
      const isNotWelcomePage = location.pathname !== routes.welcome_page
      if (isIdle && isNotWelcomePage && isTabVisible) {
        userLogoutMutation.mutate()
      }
    }, [isIdle]) // eslint-disable-line

    useEffect(() => {
      const updatedProfileImage = getUserProfilePic()
      if(updatedProfileImage && profileImage !== updatedProfileImage) {
        setProfileImage(updatedProfileImage)
      }
    }, [user, posUserInfo])

    return (
      <>


        {/* {loginStatus?.isLoggedIn && !isUserPos && ( */}
          <div className='qr-code-scan me-3 ms-3 icon-only-link'>
          {/* <div className='qr-code-scan'> */}
            <i
              
              className='pi pi-qrcode'
              aria-label='QR Scan'
              // tooltip={useTranslation('QR code scanner')}
              // tooltipOptions={{ position: 'bottom' }}
              data-testid="qr-code-scanner"
              onClick={() => {
                // cleanup qr modal data
                cleanUpScannedData()
                // open modal with QR code scanner
                setScanner(true)
                setShowModal(true)
                // Google Analytics
                trackEvent({
                  eventName: "qr_scan",
                  value: {
                    qr_scanner: "navigation_bar",
                  },
                });
              }}
            ></i>
             {/* <Button
              icon='pi pi-qrcode'
              className='p-button-rounded p-button-text p-button-lg'
              aria-label='QR Scan'
              // tooltip={useTranslation('QR code scanner')}
              // tooltipOptions={{ position: 'bottom' }}
              data-testid="qr-code-scanner"
              onClick={() => {
                // cleanup qr modal data
                cleanUpScannedData()
                // open modal with QR code scanner
                setScanner(true)
                setShowModal(true)
                // Google Analytics
                trackEvent({
                  eventName: "qr_scan",
                  value: {
                    qr_scanner: "navigation_bar",
                  },
                });
              }}
            /> */}
          </div>
        {/* )} */}

        {/* qr code scanner modal */}
        <CommonModal
          modalContent={modalContent}
          onHide={commonModalHideAction}
          show={showModal}
          title={useTranslation(QR_CODE_SCANNER, 'none')}
          hideFooter={true}
        />
        {/* toaster component */}
        <ToastAlert
          dataTestId='-redeem-voucher'
          show={showToast}
          onClose={setToast}
          message={toastMessage}
          variant={toastVariant}
        />

        <Dropdown className='logout-container' align='end'>
          <Dropdown.Toggle
            variant=' '
            id='dropdown-menu-align-end' /* id="dropdown-basic" */
          >
            <img
              src={profileImage}
              height='40px'
              width='40px'
              className='rounded-circle me-1 user-image'
              alt=''
            />
            <span className='login-user'>
              {localStorage.getItem(USER_NAME) ? (
                localStorage.getItem(USER_NAME)
              ) : (
                <JTranslation text='Guest' typeCase="pascal" />
              )}
            </span>
          </Dropdown.Toggle>

          {loginStatus?.isLoggedIn && (
            <Dropdown.Menu align='end'>
                <Dropdown.Item
                className='menu-item login-user-menu-item p-3'
                data-testid="loggedin-menu-dropdown"
              >
                <div className="d-flex">
                  <span>
                    <i className="ri-user-3-line"></i>
                  </span>
                  <span className='login-user'>
                    {localStorage.getItem(USER_NAME) ? (
                      localStorage.getItem(USER_NAME)
                    ) : (
                      <JTranslation text='Guest' typeCase="pascal" />
                    )}
                  </span>
                </div>
              </Dropdown.Item>
              {showDashboard(queryClient) &&
                GUEST_LOCATIONS.includes("/" + location.pathname.split("/")[1]) && (
                  <>

                    {/*  <hr className='my-1'></hr> */}
                    <Dropdown.Item
                      className='menu-item my-2'
                      data-testid="manage-option"
                      onClick={() => {
                        saveMenuSelection(queryClient, undefined)
                        navigate(routes.dashboard)
                      }}
                    >
                     <i className="ri-settings-4-line"></i> <JTranslation typeCase="pascal" text={"Manage"} />
                    </Dropdown.Item>

                  </>
                )}
              {location.pathname !== routes.welcome_page && (
                <>
                  <Dropdown.Item
                    className='menu-item my-2'
                    data-testid="welcome-option"
                    onClick={() => {
                      dataCleanUp()
                      navigate(routes.welcome_page)
                    }}
                  >
                    <i className="ri-computer-line"></i>
                    <JTranslation text='Welcome' typeCase="pascal" />
                  </Dropdown.Item>

                </>
              )}
              {SPECIAL_MENU_LOCATIONS.some((menuLocation) => location.pathname.startsWith(menuLocation)) &&
                !location.pathname.startsWith(routes.special_menu) && (
                  <>
                    <Dropdown.Item
                      className='menu-item my-2'
                      data-testid="special-menu-option"
                      onClick={() => navigate(`${routes.special_menu}/${menuCode}`)}
                    >
                      <JTranslation typeCase="pascal" text={"Special Menu"} />
                    </Dropdown.Item>

                  </>
                )}


              {location.pathname !== routes.menu_view && (
                <>
                  <Dropdown.Item
                    className='menu-item my-2'
                    data-testid="menu-view-option"
                    onClick={() => {
                      dataCleanUp()
                      navigate(routes.menu_view)
                    }}
                  ><i className="ri-menu-2-line"></i>
                    {SPECIAL_MENU_LOCATIONS.some((menuLocation) => location.pathname.startsWith(menuLocation)) 
                      ? <JTranslation typeCase="pascal" text={"F&B Menu"} />
                      : <JTranslation typeCase="pascal" text={"Menu View"} />
                    }
                  </Dropdown.Item>
                 {/*  <hr className='my-1'></hr> */}
                </>
              )}
              {loginStatus?.isLoggedIn &&
                <Dropdown.Item className="dropdown-logout py-3" data-testid="logout-option" onClick={() => userLogoutMutation.mutate()}>
                  <i className='ri-logout-circle-r-line mr-1'></i> <JTranslation typeCase="pascal" text={"Logout"} />
                </Dropdown.Item>
              }
            </Dropdown.Menu>
          )}

          {!loginStatus?.isLoggedIn && (
            <Dropdown.Menu align='end'>
              <Dropdown.Item
                className='menu-item login-user-menu-item p-3'
                data-testid="loggedout-menu-dropdown"
              >
                <div className="d-flex">
                  <span>
                    <i className="ri-user-3-line"></i>
                  </span>
                  <span className='login-user'>
                    <JTranslation text='Guest' typeCase="pascal" />
                  </span>
                </div>
              </Dropdown.Item>
              {location.pathname !== routes.welcome_page && (
                <>
                  <Dropdown.Item
                    className='menu-item my-2'
                    data-testid="welcome-option"
                    onClick={() => navigate(routes.welcome_page)}
                  >
                    <i className="ri-computer-line"></i>
                    <JTranslation text='Welcome' typeCase="pascal" />
                  </Dropdown.Item>
                  {/*  <hr className='my-1'></hr> */}
                </>
              )}
              <Dropdown.Item
                className='menu-item py-3 dropdown-login'
                data-testid="login-option"
                onClick={() => navigate(routes.login)}
              >
                <i className='ri-login-circle-line mr-1'></i> <JTranslation typeCase="pascal" text={"Login"} />
              </Dropdown.Item>
            </Dropdown.Menu>
          )}
        </Dropdown>
      </>
    )
}

export default HeaderDropDown;