import { useState } from 'react';
import '../../assets/styles/scss/main.scss';
import { AxiosResponse } from "axios";
import { FileType, AlertVariant } from '../../constants/constants';
import { initialWelcomeImageData, getWelcomeScreenConfigData, updateWelcomeScreenConfig } from '../../helpers/welcomeConfigHelper';
import { uploadFile } from '../../helpers/fileUploadHelper';
import { useQueryClient } from 'react-query';
import { WELCOME_CONFIG } from '../../constants/queryKeys';
import { WelcomeConfig, UploadedWelcomeImage } from '../../constants/staticTypes';
import AiTextGenerator from '../../components/ai_text_generator/AiTextGenerator';
import InnerLoader from '../../components/loader/InnerLoader';
import menuImg from '../../assets/images/menu/no_image_upload.png';
import ToastAlert from '../../components/alert/ToastAlert';
import useMutationHook from '../../hooks/useMutationHook';
import useQueryHook from '../../hooks/useQueryHook';
import WysiwygEditorBasic from '../../components/wysiwyg_editor/WysiwygEditorBasic';
import {
    AI_TEXT_GENERATOR,
    FILE_UPLOAD_LIMIT,
    SAVE,
    UPLOAD_PHOTO_MESSAGE,
    UPLOAD_PHOTO,
    UPLOAD_WELCOME_SCREEN_IMAGE,
    USER_UPLOAD_PHOTO_ERROR,
    USER_UPLOAD_VIDEO_ERROR,
    WELCOME_SCREEN_UPDATED, WELCOME_TEXT
} from '../../constants/strings';
import { JTranslation } from '../../helpers/jTranslate';
import { convertTextToHTML, escapeHtml, unescapeHtml } from '../../helpers/utils';

function WelcomeScreenConfig() {
    // LOCAL VARIABLES
    const queryClient = useQueryClient()
    const welcomeMutation = useMutationHook(queryClient, true)
    // TOAST VARIABLES
    const [showToast, setToast] = useState(false) // Toast alert state
    const [toastMessage, setToastMessage] = useState('') // Toast message
    const [toastVariant, setToastVariant] = useState('') // Toast Variant
    // STATE VARIABLES
    const [welcomeText, setWelcomeText] = useState('')
    const [welcomeImage, setWelcomeImage] = useState<UploadedWelcomeImage>(initialWelcomeImageData)
    const [showAiTextGenerator, setShowAiTextGenerator] = useState(false)

    // set welcome config data to variables 
    const onGetWelcomeConfigSuccess = (res: AxiosResponse) => {
        const config: WelcomeConfig = res.data.data
        setWelcomeImage({ key: config.imageKey, signedUrl: config.signedUrl })
        setWelcomeText(config.welcomeRichTextData)
    }

    // fetch welcome config data
    const welcomeConfigPage = useQueryHook(WELCOME_CONFIG, getWelcomeScreenConfigData, onGetWelcomeConfigSuccess)

    // show toast
    const displayToast = (message: string, variant: string) => {
        setToastVariant(variant)
        setToastMessage(message)
        setToast(true)
    }

    // upload photo success
    const onUploadSuccess = (key: string, imageUrl: string, _fileType: FileType) => {
        setWelcomeImage({ key, signedUrl: imageUrl })
    }

    // on file upload error
    const onUploadError = (fileType: FileType) => {
        displayToast(
            fileType === FileType.IMAGE ? USER_UPLOAD_PHOTO_ERROR : USER_UPLOAD_VIDEO_ERROR,
            AlertVariant.ERROR
        )
    }

    // on config update success
    const onConfigSuccess = () => {
        setToastVariant(AlertVariant.SUCCESS);
        setToastMessage(WELCOME_SCREEN_UPDATED);
        setToast(true);
    }

    // on config update error
    const onConfigError = (message: string, variant: string) => {
        setToastVariant(variant);
        setToastMessage(message);
        setToast(true);
    }

    return (
        <>
            <ToastAlert show={showToast} onClose={setToast} message={toastMessage} variant={toastVariant} />
            {/* spinner */}
            {welcomeConfigPage.isFetching && <InnerLoader />}

            {/* welcome config component */}
            {!welcomeConfigPage.isFetching && (
                <div className='row h-100  overflow-auto'>
                    <div className="col-md-5 col-lg-4 h-100">
                        <div className="card roles-link h-100">
                            <div className="card-body h-100  mb-3 pt-0 d-flex justify-content-center">
                                <div className="roles-sticky-header py-3">
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="flex-grow-1 ">
                                            {/*   <h5 className="d-flex align-items-center card-title mb-0">
                                                {WELCOME_IMAGE}
                                            </h5> */}
                                        </div>
                                    </div>
                                    <div className=" flex-grow-0 ">
                                        {/* <!---image----> */}
                                        {
                                            <div className="mb-2">
                                                <div
                                                    className={
                                                        welcomeImage.signedUrl
                                                            ? 'img-thumb-main'
                                                            : 'no-image-avathar img-thumb-main'
                                                    }
                                                >
                                                    <div className="view-img-preview welcome-img-box">
                                                        {welcomeImage.signedUrl ? (
                                                            <img
                                                                src={welcomeImage.signedUrl}
                                                                className="img-fluid rounded-1"
                                                                alt=" "
                                                            />
                                                        ) : (
                                                            <img
                                                                src={menuImg}
                                                                className="img-fluid rounded-1"
                                                                alt=" "
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="preview-tool text-end">
                                                        <i
                                                            className="ri-delete-bin-6-line delete"
                                                            onClick={() => {
                                                                setWelcomeImage(initialWelcomeImageData)
                                                            }}
                                                        ></i>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* IMAGE UPLOADER */}
                                        <div className="col-12 mb-3">
                                            <div className="mb-3 d-flex flex-column upload-c">
                                                <label
                                                    htmlFor="history-upload"
                                                    className={`custom-file-upload btn  btn-custom-primary-outline w-100                                 
                                            ${welcomeImage.key && ' disabled'}`}
                                                >
                                                    <i className="ri-upload-line"></i> <JTranslation typeCase="pascal" text={UPLOAD_PHOTO} />                                                </label>
                                                <input
                                                    id="history-upload"
                                                    type="file"
                                                    accept="image/*"
                                                    autoComplete="off"
                                                    disabled={welcomeImage.key ? true : false}
                                                    data-testid="upload-img"
                                                    onChange={(e) => {
                                                        uploadFile(
                                                            FileType.IMAGE,
                                                            e,
                                                            welcomeMutation,
                                                            onUploadSuccess,
                                                            onUploadError,
                                                            displayToast
                                                        )
                                                    }}
                                                />
                                                <div className="mt-2  text-secondary fw-bold text-center">
                                                    <JTranslation typeCase="pascal" text={UPLOAD_WELCOME_SCREEN_IMAGE} />
                                                </div>
                                                <div className="mt-2 small text-secondary text-center"><JTranslation typeCase="pascal" text={UPLOAD_PHOTO_MESSAGE} /></div>
                                                <div className="mt-2 small text-secondary text-center"><JTranslation typeCase="pascal" text={FILE_UPLOAD_LIMIT} /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-7 col-lg-8 h-100">
                        <div className="card  roles-link h-100">
                            <div className="card-header flex-grow-0">
                                <div className="d-flex mb-2 mt-2 align-items-center">
                                    <div className="flex-grow-1">
                                        <h4 className="mb-0 title"> <JTranslation typeCase="pascal" text={WELCOME_TEXT} /></h4>
                                    </div>
                                    <div className="flex-grow-0 d-flex justify-content-end ">
                                        <button className="btn btn-sm btn-custom-primary-outline" data-testid="ai-text-btn" onClick={() => { setShowAiTextGenerator(!showAiTextGenerator) }}>
                                        <i className="ri-ai-generate-text"></i> <JTranslation typeCase="pascal" text={AI_TEXT_GENERATOR} />
                                        </button>
                                    </div>
                                </div>
                            </div>


                            <div className="card-body mt-3 h-100  mb-3 pt-0">
                                <div className='col-12'>
                                    <AiTextGenerator
                                        key='welcomeScreen'
                                        show={showAiTextGenerator}
                                        callBack={(aiResponse: string) => {
                                            const htmlString = convertTextToHTML(aiResponse)
                                            setWelcomeText(htmlString)
                                        }}
                                        type='welcomeScreen'
                                    />
                                </div>
                                <div className=" flex-grow-0 ">
                                    <WysiwygEditorBasic
                                        editorContent={unescapeHtml(welcomeText)}
                                        data-testid="welcome-description"
                                        callback={(value) => {
                                            setWelcomeText(escapeHtml(value))
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )}

            <div className="save-btn-section shadow save-btn-absolute">
                <button
                    className="btn btn-custom-primary"
                    type="button"
                    disabled={welcomeConfigPage.isLoading || !welcomeImage.key || !welcomeText ? true : false}
                    data-testid="save-btn"
                    onClick={() => {
                        // update API call
                        updateWelcomeScreenConfig(
                            welcomeMutation,
                            { imageKey: welcomeImage.key, welcomeRichTextData: welcomeText },
                            onConfigSuccess,
                            onConfigError)
                    }}
                >
                    <JTranslation typeCase="pascal" text={SAVE} />
                </button>
            </div>
        </>
    )
}

export default WelcomeScreenConfig
