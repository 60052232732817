import { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { Carousel } from 'primereact/carousel';
import { DayOfWeek } from '../../constants/staticTypes';
import { generateWeekdayDates, Weekday, days, PublishedSchedule, generateTwoWeekdayDates, getDateOfWeekday, Holidays, MandatoryDay, isDateInRange } from '../../helpers/workTimeScheduleHelper';
import { BD, BUSINESS_CLOSE, HOLIDAY, MANDATORY, MANDATORY_POPUP_CONTENT, NO_SHIFT_ASSIGNED, NO_TITLE } from '../../constants/strings';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { useWindowSize } from 'react-use';
import { MOBILE_VIEW_BREAKPOINT } from '../../constants/constants';
import { JTranslation } from '../../helpers/jTranslate';
import { Skeleton } from 'primereact/skeleton';
import MlxPopover from '../common/MlxPopover';

type Props = {
    selectedWeek: dayjs.Dayjs,
    parent?: string
    scheduleData: PublishedSchedule[]
    isFetching: boolean
    holidays?: Holidays[]
    mandatoryDays?: MandatoryDay[]
}

const StaffScheduleMobileView = ({ selectedWeek, scheduleData, isFetching, parent, holidays = [], mandatoryDays = [] }: Props) => {
    const [weekdayDates, setWeekdayDates] = useState<Weekday[]>([]);
    const [page, setPage] = useState(0);
    const [currentDay, setCurrentDay] = useState<{ day: DayOfWeek, occurrence: number, index: number } | null>(null);
    const [currentOccurrence, setCurrentOccurrence] = useState(0);
    const { width } = useWindowSize();
    const breakPoint = MOBILE_VIEW_BREAKPOINT;

    const [containerElement, setContainerElement] = useState<HTMLDivElement | undefined>(undefined);

    // Generate unique days list with occurrence index
    const uniqueDays = [...Array(2)].flatMap((_, week) =>
        days.map((day, index) => ({
            day,
            occurrence: week,
            index: index + (week * 7)
        }))
    );

    useEffect(() => {
        // Calculate the index to set the page
        // If today is Sunday (0), we should go to the next Monday which is at index 7 in uniqueDays
        // For other days, we should go to the next day in the current week
        const todayIndex = dayjs().day(); // Get current day of the week (0 for Sunday, 1 for Monday, etc.)

        // Determine the next day index (if today is Sunday, go to next Monday)
        const nextDayIndex = todayIndex === 0 ? 7 : todayIndex;

        setPage(nextDayIndex);

        const containerElement = document.getElementsByClassName('card-body flex-grow-1 overflow-auto')[0] as HTMLDivElement;
        setContainerElement(containerElement ?? undefined)
    }, []);

    useEffect(() => {
        if (selectedWeek) {
            const dates = parent === 'dashboard' ? generateTwoWeekdayDates(selectedWeek) : generateWeekdayDates(selectedWeek);
            setWeekdayDates(dates);
        }
    }, [selectedWeek]);

    const getFormattedDate = (day: DayOfWeek, format= 'ddd, D MMM YYYY') => {
        let date = ''; 
        weekdayDates?.forEach((weekDay) => {
            if (weekDay.day === day) {
                date = dayjs(weekDay.date).format(format);
            }
        });
        return date;
    }

    const getUpcomingFormattedDate = (day: DayOfWeek | undefined, occurrence: number) => {
        let date = '';
        let count = 0;

        weekdayDates?.forEach((weekDay) => {
            if (weekDay.day === day) {
                if (count === occurrence) {
                    date = dayjs(weekDay.date).format('ddd, D MMM YYYY');
                }
                count++;
            }
        });

        return date;
    }

    const getScheduleFormattedDate = (day: DayOfWeek, occurrence: number) => {
        let date = '';
        let count = 0;

        weekdayDates?.forEach((weekDay) => {
            if (weekDay.day === day) {
                if (count === occurrence) {
                    date = dayjs(weekDay.date).format('YYYY-MM-DD'); // Use ISO string format
                }
                count++;
            }
        });

        return date;
    }

    const cardBodyTemplate = (weekDay: DayOfWeek) => {
        const data = scheduleData
            .filter(item => {
                const { scheduleDate } = item;
                const currentCellDate = getDateOfWeekday({ day: weekDay, selectedWeek ,format: 'YYYY-MM-DD'});
                return scheduleDate && scheduleDate === currentCellDate;
            })
            .map(item => {
                const { assignedShifts } = item;
                const { shiftName, startTime, endTime, role, isBd, isClose } = assignedShifts;
                return (
                    <div key={item.id} className='staff-schedule-dashboard-card'>
                        <div className="staff-schedule-dashboard">
                            {isClose ? <div className="time">{`${startTime} - "Close"`}</div> : ""}
                            {isBd ? <div className="time">{`${startTime} - "V"`}</div> : ""}
                            {!isBd && !isClose ? <div className="time">{`${startTime} - ${endTime}`}</div> : ''}
                            <div className="role">{role}</div>
                        </div>
                    </div>
                );
            });

            if (data?.length === 0) {
                return (
                    <div className="staff-schedule-card-no-reservation">
                        <div className="staff-schedule-dashboard"><JTranslation typeCase="none" text={NO_SHIFT_ASSIGNED} /></div>
                    </div>
                );
            }

        return <>{data}</>;
    }

    // New upcomingCardBodyTemplate function for two weeks
    const upcomingCardBodyTemplate = (weekDay: DayOfWeek, occurrence: number) => {
        // Find the date corresponding to this occurrence of the day
        const specificDate = getScheduleFormattedDate(weekDay, occurrence);

        // Filter and map valid schedule entries
        const data = scheduleData
            .filter(item => {
                const { scheduleDate } = item;
                return scheduleDate && scheduleDate === specificDate;
            })
            .map(item => {
                const { assignedShifts } = item;
                const { shiftName, startTime, endTime, role, isBd, isClose } = assignedShifts;
                return (
                    <div key={uuidv4()} className='staff-schedule-dashboard-card'>
                        <div className="staff-schedule-dashboard">
                            {isClose ? <div className="time">{`${startTime} - "Closing Time"`}</div> : null}
                            {isBd ? <div className="time">{`${startTime} - "${BD}"`}</div> : null}
                            {!isBd && !isClose ? <div className="time">{`${startTime} - ${endTime}`}</div> : null}
                            <div className="role">{role}</div>
                        </div>
                    </div>
                );
            });

        if (data.length === 0) {
            return (
                <div className="staff-schedule-card-no-reservation">
                    <div className="staff-schedule-dashboard"><JTranslation typeCase="none" text={NO_SHIFT_ASSIGNED} /></div>
                </div>
            );
        }

        return <>{data}</>;
    }

    const dayTemplate = ({ day, occurrence, index }: { day: DayOfWeek, occurrence: number, index: number }) => {
        return (
            <Card key={index} className='data-view-card mb-3 border-0 shadow-none upcoming-schedule h-100 min-hgt-195'>
                {/* <p>{getUpcomingFormattedDate(day, occurrence)}</p> */}
                {isFetching ? 
                    <Skeleton height='4rem'></Skeleton>
                    :
                    <div className="dashboard-schedule-scroll">
                        {upcomingCardBodyTemplate(day, occurrence)}
                    </div>
                }
            </Card>
        );
    }

    const upcomingDayHeader = (dayDetails:any) => {
        return (
            <p className='text-center mb-0 mt-1' style={{fontSize: "1rem"}}>{getUpcomingFormattedDate(dayDetails.day, dayDetails.occurrence)}</p>
        )
    } 

    const checkHoliday = (date: string) => {
        const isHolidays = holidays.filter((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate })).length > 0
        return isHolidays
    };

    const getHolidayTitle = (date: string) => {
        const holiday = holidays.find((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate }))
        return holiday?.title ?? NO_TITLE
    };

    const getIsOpenForBusiness = (date: string) => {
        const holiday = holidays.find((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate }))
        return holiday ? holiday.isOpenForBusiness : false
    };

    const checkMandatoryDays = (date: string) => {
        const isMandatory = mandatoryDays.filter((mandatoryDay) => isDateInRange({ date: date, startDate: mandatoryDay.startDate, endDate: mandatoryDay.endDate })).length > 0
        return isMandatory
    };

    return (
        <>
            {parent === 'dashboard' ? (
                <Card className='data-view-card mb-3 h-100 shadow-none'>
                    <p className='fw-bold mb-1'><JTranslation typeCase="pascal" text={"Upcoming Schedule"} /></p>
                    <Carousel
                        value={uniqueDays}
                        className='d-flex align-items-center justify-content-center mb-2'
                        numVisible={1}
                        numScroll={1}
                        itemTemplate={(item) => upcomingDayHeader(item)}
                        page={page}
                        onPageChange={(e) => {
                            setPage(e.page);
                            setCurrentDay(uniqueDays[e.page]);
                            setCurrentOccurrence(uniqueDays[e.page].occurrence);
                        }}
                        showIndicators={false}
                    />
                    {currentDay && dayTemplate(currentDay)}
                </Card>
            ) : (
                <div className="card">
                    {days.map(day => {
                        const date = getFormattedDate(day, 'YYYY-MM-DD');
                        return (
                            isFetching
                            ? (
                                <Card key={uuidv4()} className='data-view-card mb-3 h-100 '>
                                    <Skeleton width='100%' height="6rem"/>
                                </Card>
                            )
                            : (
                                <Card key={uuidv4()} className='data-view-card mb-3 h-100 '>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <p className='py-2 m-0'>{getFormattedDate(day)}</p>
                                        <span>
                                            {checkMandatoryDays(date)
                                                ?   <span className="help-main ms-3">
                                                        <MlxPopover 
                                                            data={{ title: MANDATORY, body: MANDATORY_POPUP_CONTENT }} 
                                                            iconName="ri-calendar-check-line"
                                                            iconStyles={{ color: 'var(--primary-color)', fontSize: '1.2rem' }}
                                                            containerElement={containerElement}
                                                        />
                                                    </span>
                                                : checkHoliday(date)
                                                    ?   <span className="help-main ms-3">
                                                            <MlxPopover 
                                                                data={{ title: getIsOpenForBusiness(date) 
                                                                    ? HOLIDAY 
                                                                    : BUSINESS_CLOSE, body: getHolidayTitle(date) 
                                                                }} 
                                                                iconName="ri-calendar-close-line"
                                                                iconStyles={{ color: 'var(--danger-color)', fontSize: '1.2rem' }}
                                                                containerElement={containerElement}
                                                            />
                                                        </span>
                                                    : <></>
                                            }
                                        </span>
                                    </div>
                                    {cardBodyTemplate(day)}
                                </Card>
                            )
                        )
                    })}
                </div>
            )}
        </>
    );
}

export default StaffScheduleMobileView;
