import React, { useEffect, useState } from "react";
import withSidebar from "../../hoc/withSidebar";
import { JTranslation } from "../../helpers/jTranslate";
import { InputText } from "primereact/inputtext";
import {
  Accordion,
  AccordionTab,
  AccordionTabChangeEvent,
} from "primereact/accordion";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import "./help.scss";
import { HELP_LIST } from "../../constants/queryKeys";
import useQueryHook from "../../hooks/useQueryHook";
import { AxiosResponse } from "axios";
import {
  getHelpList,
  HelpItem,
  ImageComponentProps,
  unescapeMarkdown,
} from "../../helpers/helpHelper";
import MediaThemeSutro from "player.style/sutro/react";
import Loader from "../../components/loader/Loader";
import { Message } from "primereact/message";
import { Image } from "antd";
import fallBackImage from "../../assets/images/menu/600x400.png";

function Help() {
  const [helpDataList, setHelpDataList] = useState<HelpItem[]>();
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredHelpData, setFilteredHelpData] = useState<
    HelpItem[] | undefined
  >([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const onHelpListFetchSuccess = (res: AxiosResponse<any, any>) => {
    setHelpDataList(res.data.data.data);
    setInitialLoad(false);
  };

  const helpData = useQueryHook(
    HELP_LIST,
    () => getHelpList("", 100, ""),
    onHelpListFetchSuccess,
  );

  useEffect(() => {
    const filtered = helpDataList?.filter(
      (item) =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        unescapeMarkdown(item.content)
          .toLowerCase()
          .includes(searchTerm.toLowerCase()),
    );
    setFilteredHelpData(filtered);
  }, [searchTerm, helpDataList]);

  // Highlight the search term in the text
  const highlightSearchTerm = (text: string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, "gi");
    return text.replace(regex, "<span class='help-search-highlight'>$1</span>");
  };

  const VideoPlayer: React.FC<{ src: string }> = ({ src }) => {
    const isYouTube = src.includes("youtube.com") || src.includes("youtu.be");
    const isVimeo = src.includes("vimeo.com");

    if (isYouTube || isVimeo) {
      return (
        <div className="video-embed">
          <iframe
            title="video-embed"
            src={
              isYouTube
                ? `https://www.youtube.com/embed/${extractYouTubeId(src)}`
                : `https://player.vimeo.com/video/${extractVimeoId(src)}`
            }
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      );
    }

    return (
      <MediaThemeSutro
        style={{ "--media-accent-color": "#feba01" } as React.CSSProperties}
        className="tutorial-video"
      >
        <video slot="media" src={src} crossOrigin="anonymous"></video>
      </MediaThemeSutro>
    );
  };

  const extractYouTubeId = (url: any) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/,
    );
    return match ? match[1] : null;
  };

  const extractVimeoId = (url: any) => {
    const match = url.match(/(?:vimeo\.com\/(?:.*#|.*\/videos\/)?)([0-9]+)/);
    return match ? match[1] : null;
  };

  const ImageComponent: React.FC<ImageComponentProps> = ({
    src,
    alt,
    width,
    style,
  }) => {
    return (
      <div
        className="image-wrapper"
        style={{ display: "flex", justifyContent: "left", margin: "0 0 30px 0" }}
      >
        <Image
          src={src}
          alt={alt || "Image"}
          className="custom-image"
          preview
          width={width || "100%"} // Default to 100% if width is not provided
          style={{
            maxWidth: "100%",
            height: "auto",
            border:"1px solid #ddd",
            padding:"0.5rem",
            ...style, // Merge with any other inline styles
          }}
          fallback={fallBackImage}
        />
      </div>
    );
  };

  const parseAlerts = (content: string) => {
    // Define regex to capture alert blocks with ::: and alert types
    const alertRegex = /:::(warning|info|success|danger|tip)\n([\s\S]*?)\n:::/g;

    return content.replace(alertRegex, (match, alertType, message) => {
      return `<div class="callout-${alertType}">${message}</div>`;
    });
  };

  const onHelpMenuChange = (event: AccordionTabChangeEvent) => {
    setActiveIndex(event.index as number);

    // TODO: best way to handle the conversion in markdown library
    setTimeout(() => {
      const currentOpenAccordianElement = document.querySelector(
        `.help-accordian-tab-${event.index}`,
      );

      const videoElements =
        currentOpenAccordianElement?.querySelectorAll(`.tutorial-video`) ?? [];

      for (const element of videoElements) {
        if (element.parentElement) {
          element.parentElement.outerHTML = element.outerHTML;
        }
      }
    }, 800);
  };

  return (
    <>
      <div className="page-title">
        <h5 className="d-flex">
          <JTranslation typeCase="pascal" text={"Help"} />
        </h5>
      </div>

      <div className="col-md-12 col-lg-12 mb-3 h-100">
        <div className="card h-100 overflow-hidden">
          <div className="card-body overflow-hidden p-0">
            <div className=" h-100 pb-5 overflow-auto">
              <div className="col-12 p-0">
                <div className="header-section help-banner">
                  <div className="col-10 col-sm-10 col-md-5 col-lg-4">
                    <h5 className="mb-2">
                      <JTranslation
                        typeCase="none"
                        text={"How can we help you"}
                      />
                    </h5>
                    <div className="p-input-icon-left w-100">
                      <i className="pi pi-search" />
                      <InputText
                        placeholder="Search"
                        className="w-100"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {helpData.isLoading || initialLoad ? (
                  <Loader />
                ) : (
                  <div className="help-accordion-section">
                    <Accordion
                      onTabChange={onHelpMenuChange}
                      activeIndex={activeIndex}
                    >
                      {filteredHelpData?.map((helpItem, index) => (
                        <AccordionTab
                          key={index}
                          header={
                            <span
                              dangerouslySetInnerHTML={{
                                __html: highlightSearchTerm(helpItem.title),
                              }}
                            />
                          }
                        >
                          <div>
                            <div
                              style={{ marginBottom: "1rem" }}
                              className={`help-accordian-tab-${index}`}
                            >
                              <Markdown
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                                components={{
                                  div: ({ className, children }) => {
                                    const alertMapping: {
                                      [key: string]: string;
                                    } = {
                                      "callout-warning": "warning",
                                      "callout-info": "info",
                                      "callout-tip": "success",
                                      "callout-danger": "danger",
                                      "callout-success": "success",
                                    };
                                    type AlertType =
                                      | "info"
                                      | "warn"
                                      | "error"
                                      | "success";

                                    const alertType: AlertType =
                                      // @ts-ignore
                                      alertMapping[className || undefined];

                                    return alertType ? (
                                      <Message
                                        severity={alertType}
                                        text={children}
                                      />
                                    ) : (
                                      <div>{children}</div>
                                    );
                                  },
                                  a: ({ href, children }) => {
                                    if (
                                      href &&
                                      (/\.(mp4|webm|ogg|avi|mov|flv)$/i.test(
                                        href,
                                      ) ||
                                        href.includes("videos-bucket") ||
                                        href.includes("youtube.com") ||
                                        href.includes("youtu.be") ||
                                        href.includes("vimeo.com"))
                                    ) {
                                      return <VideoPlayer src={href} />;
                                    }
                                    return (
                                      <a
                                        href={href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {children}
                                      </a>
                                    );
                                  },
                                  img: ({ src, alt, title, width }) => {
                                    let style: React.CSSProperties = {};
                                    return (
                                      <ImageComponent
                                        src={src || ""}
                                        alt={alt || "Image"}
                                        width={`${width}px`}
                                        style={style}
                                      />
                                    );
                                  },
                                  ol: ({ children }) => {
                                    return (
                                      <ol className="help-list-content">
                                        {children}
                                      </ol>
                                    );
                                  },
                                  ul: ({ children }) => {
                                    return (
                                      <ul className="help-list-content">
                                        {children}
                                      </ul>
                                    );
                                  },
                                }}
                              >
                                {parseAlerts(
                                  unescapeMarkdown(helpItem?.content),
                                )}
                              </Markdown>
                            </div>
                          </div>
                        </AccordionTab>
                      ))}
                    </Accordion>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withSidebar(Help);
