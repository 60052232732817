import React, { useContext, useEffect, useState } from 'react';
import { AI_TEXT_GENERATOR, AUTO_PUBLISH_DATE, CANCEL, DESCRIPTION, END_DATE, EVENT_END_TIME, EVENT_DATE, HEADING, LOCATION, PUBLISH, SAVE_AS_DRAFT, SCHEDULE, EVENT_START_TIME, TAG, UPDATE, URL_SLUG, URL_TEXT, USER_UPLOAD_PHOTO_ERROR, USER_UPLOAD_VIDEO_ERROR, HELP_EVENT_TAG, HELP_EVENT_LOCATION, HELP_EVENT_URL_TEXT, HELP_EVENT_URL_SLUG, HELP_EVENT_AUTO_PUBLISH_DATE, HELP_EVENT_START_DATE, HELP_EVENT_END_DATE, HELP_EVENT_START_TIME, HELP_EVENT_END_TIME } from '../../constants/strings';
import { AlertVariant, FileType, IMAGE_TYPE, PageMode, toastMessageInitialData } from '../../constants/constants';
import { CommonCtx } from '../../context/CommonCtxProvider';
import { CropType, EventPostForApi, EventPostFormData, Every, FetchNextOccurrence, NewsAndEventsId, PostResponseType, PostType, RecurringPattern, ToastMessageProps } from '../../constants/staticTypes';
import { ErrorMessage, Field, Form, Formik, FieldProps } from 'formik';
import { escapeHtml, getDomainFromURL, popover, unescapeHtml } from '../../helpers/utils';
import { InputSwitch } from 'primereact/inputswitch';
import { isEqual } from 'lodash';
import { JTranslation } from '../../helpers/jTranslate';
import { NEWS_AND_EVENTS_LIST } from '../../constants/queryKeys';
import { NextOccurrenceData, areDatesSame, convertToSlug, createEventApi, formatDateForApi, formatTimeForApi, getFullPostDetails, getNextEventOccurrenceApi, getValidationSchema, initialValuesForEvents, parseTimeForTimePicker, today, updateEventApi } from '../../helpers/newsAndEventsHelper';
import { OverlayTrigger } from "react-bootstrap";
import { routes } from '../../constants/routes';
import { TimePicker } from 'antd';
import { useQueryClient } from 'react-query';
import AiTextGenerator from '../ai_text_generator/AiTextGenerator';
import DatePickerComponent from '../date_picker/DatePickerComponent';
import FileUpload from '../f&b_menu/admin/add_item_forms/FileUpload';
import RecurringTimeForm from './recurring_event/RecurringTimeForm';
import ToastAlert from '../alert/ToastAlert';
import useMutationHook from '../../hooks/useMutationHook';
import WysiwygEditorBasic from '../wysiwyg_editor/WysiwygEditorBasic';
import MlxPopover from '../common/MlxPopover';

type Props = {
    onClose: Function
    pageMode: PageMode.ADD | PageMode.EDIT
    postType: PostType.NEWS | PostType.EVENT
    selectedPostId: NewsAndEventsId
}

const EventForm = ({ onClose, pageMode, selectedPostId, postType }: Props) => {
    const queryClient = useQueryClient()
    const newsAndEventsMutation = useMutationHook(queryClient, true)
    const nextOccurrenceMutation = useMutationHook(queryClient, false)
    const domainName = getDomainFromURL()
    // CONTEXT VARIABLE
    const { setShowSideBar } = useContext(CommonCtx)
    // STATE VARIABLES
    const [isLoading, setIsLoading] = useState(false)
    const [isRecurring, setIsRecurring] = useState(false)
    const [recurringPattern, setRecurringPattern] = useState<RecurringPattern>('')
    const [every, setEvery] = useState<Every>('')
    const [nextOccurrence, setNextOccurrence] = useState<string | null>(null)
    const [autoPublishDate, setAutoPublishDate] = useState<Date | null>(today)
    const [formData, setFormData] = useState<EventPostFormData>(initialValuesForEvents)
    const [prevFormData, setPrevFormData] = useState<EventPostFormData>(initialValuesForEvents)
    const [images, setImages] = useState<string[]>([])
    const [saveAsDraft, setSaveAsDraft] = useState(false)
    const [showAiTextGenerator, setShowAiTextGenerator] = useState(false)
    const [slugModified, setSlugModified] = useState(false)
    const [submitButtonName, setSubmitButtonName] = useState(pageMode === PageMode.EDIT ? UPDATE : PUBLISH)
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [videos, setVideos] = useState<string[]>([])
    // Get the current date
    const currentDate = new Date();
    // Calculate the maximum date, 5 years from now
    const maxDate = new Date();
    maxDate.setFullYear(currentDate.getFullYear() + 5);

    const cropType: CropType = 'NEWS';
    const validateImageDimension = { width: IMAGE_TYPE[cropType].width, height: IMAGE_TYPE[cropType].height };

    useEffect(() => {
        const data: FetchNextOccurrence = {
            publishDate: formatDateForApi({ date: formData.publishDate }),
            recurringPattern: formData.recurringPattern,
            recurringOptions: formData.recurringOptions!,
        }

        if (
            formData.isRecurring &&
            formData.recurringPattern &&
            !isLoading &&
            Object.keys(formData.recurringOptions || {}).length > 0 && // Check if recurringOptions is not an empty object
            Object.values(formData.recurringOptions || {}).some(value => value !== undefined) && // Check if any value in recurringOptions is not undefined
            (formData?.recurringOptions?.selectedDaysOfWeek === undefined || formData?.recurringOptions?.selectedDaysOfWeek.length > 0) && // Check if selectedDaysOfWeek is undefined or not an empty array
            !isEqual(prevFormData, formData) // Check if formData has changed from previous state using lodash isEqual
        ) {
            setIsLoading(true);
            // api call to calculate next event occurrence date 
            getNextEventOccurrenceApi(nextOccurrenceMutation, data, onNextOccurrenceSuccess, onNextOccurrenceError);
        }

        // Update prevFormData with current formData
        setPrevFormData(formData);
    }, [formData]); // eslint-disable-line


    // effect to turn on the recurring event switch
    useEffect(() => {
        setIsRecurring(formData.isRecurring)
    }, [formData])

    // effect to change submit button name
    useEffect(() => {
        if (pageMode === PageMode.EDIT) {
            setSubmitButtonName(UPDATE)
        } else {
            if (areDatesSame({ date1: autoPublishDate, date2: today })) {
                setSubmitButtonName(PUBLISH)
            } else {
                setSubmitButtonName(SCHEDULE)
            }
        }
    }, [autoPublishDate, pageMode])

    // effect to call api to get news details, if page on edit mode
    useEffect(() => {
        if (pageMode === PageMode.EDIT && selectedPostId) {
            // get news details api call
            getFullPostDetails(newsAndEventsMutation, selectedPostId, onGetDetailsSuccess, onError)
        }
    }, [pageMode, selectedPostId]) // eslint-disable-line

    const getRouteForSlug = () => {
        if (postType === PostType.NEWS) {
            return routes.news_in_detail
        } else {
            return routes.events_in_detail
        }
    }

    // on delete image or video
    const onDeleteFile = (fileType: FileType, index: number) => {
        if (fileType === FileType.IMAGE) {
            const updatedPhotoKeys = [...formData.photoKeys]
            updatedPhotoKeys.splice(index, 1)
            setFormData({ ...formData, photoKeys: updatedPhotoKeys })

            const updatedImages = [...images]
            updatedImages.splice(index, 1)
            setImages(updatedImages)
        } else {
            const updatedVideoKeys = [...formData.videoKeys]
            updatedVideoKeys.splice(index, 1)
            setFormData({ ...formData, videoKeys: updatedVideoKeys })

            const updatedVideos = [...videos]
            updatedVideos.splice(index, 1)
            setVideos(updatedVideos)
        }
    }

    // on file upload success
    const onUploadSuccess = (key: string, fileUrl: string, fileType: FileType) => {
        if (fileType === FileType.IMAGE) {
            setFormData({ ...formData, photoKeys: [...formData.photoKeys, key] })
            setImages([...images, fileUrl])
        } else {
            setFormData({ ...formData, videoKeys: [...formData.videoKeys, key] })
            setVideos([...videos, fileUrl])
        }
    }

    // on file upload error
    const onUploadError = (fileType: FileType) =>
        displayToast(
            fileType === FileType.IMAGE ? USER_UPLOAD_PHOTO_ERROR : USER_UPLOAD_VIDEO_ERROR,
            AlertVariant.ERROR
        )

    const displayToast = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    const onGetDetailsSuccess = (res: PostResponseType) => {
        const { every, isRecurring, recurringPattern, recurringOptions, description, endDate, endTime, location, photoKeys, postTags, publishDate, saveAction, signedPhotosKeys, signedVideoKeys, startDate, startTime, title, urlSlug, videoKeys } = res;
        setImages(signedPhotosKeys)
        setVideos(signedVideoKeys)

        setFormData((prevValues) => {
            const apiData = {
                ...prevValues,
                publishDate: new Date(publishDate),
                startDate: startDate ? new Date(startDate) : null,
                endDate: endDate ? new Date(endDate) : null,
                description,
                location,
                photoKeys,
                postTags,
                saveAction,
                startTime,
                endTime,
                recurringStartTime: startTime,
                recurringEndTime: endTime,
                title,
                urlSlug,
                videoKeys,
                isRecurring: Boolean(isRecurring),
                every,
                recurringPattern,
            }

            if (res.isRecurring && res.recurringPattern === "monthly" && res.every === "every-weekday") {
                const updatedRecurringOptions = { ...recurringOptions, repeatsEveryNthMonthRow2: recurringOptions?.repeatsEveryNthMonth };
                delete updatedRecurringOptions.repeatsEveryNthMonth;
                return {
                    ...apiData,
                    ...updatedRecurringOptions,
                };
            } else {
                return {
                    ...apiData,
                    ...recurringOptions
                };
            }

        })
    }

    const onNextOccurrenceSuccess = (data: NextOccurrenceData) => {
        setNextOccurrence(data.nextOccurrenceDate)
        setIsLoading(false)
    }

    const onNextOccurrenceError = () => {
        setIsLoading(false)
    }

    const onSuccess = (message: string) => {
        setToastMessage({
            message: message,
            show: true,
            variant: AlertVariant.SUCCESS,
        })

        // list api recall
        queryClient.refetchQueries([NEWS_AND_EVENTS_LIST]) // nosonar

        setTimeout(() => {
            setShowSideBar(false)
        }, 2000)
    }

    const onError = (error: string) => {
        setToastMessage({
            message: error,
            show: true,
            variant: AlertVariant.ERROR,
        })
    }

    const handleAutoPublish = (value: Date | null) => {
        setAutoPublishDate(value)
    }

    const handleTitleChange = (value: string) => {
        setFormData((prevValues) => {
            return { ...prevValues, title: value, urlSlug: convertToSlug(value) }
        })
    }

    const handleURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        value ? setSlugModified(true) : setSlugModified(false)
        setFormData((prevValues) => {
            return { ...prevValues, urlSlug: convertToSlug(value) }
        })
    }

    const validate = (values: EventPostFormData) => {
        handleAutoPublish(values.publishDate)

        setFormData((prevValues) => {
            const newData = {
                ...prevValues,
                ...values,
            }
            // recurring events 
            if (values.isRecurring) {
                const { every, recurringPattern } = values
                setEvery(every)
                setRecurringPattern(recurringPattern)

                // clear recurring form data when recurring pattern changes
                if ((recurringPattern !== prevValues.recurringPattern) || (every !== prevValues.every)) {
                    delete newData.every
                    delete newData.recurringOptions
                    delete newData.repeatsEveryNthDay
                    delete newData.repeatsEveryNthMonth
                    delete newData.repeatsEveryNthMonthRow2
                    delete newData.repeatsEveryNthWeek
                    delete newData.selectedDaysOfWeek
                    delete newData.selectedMonthDay
                    delete newData.selectedOrdinal
                    delete newData.selectedYearMonth
                }

                // adding recurring data
                newData.every = every
                newData.recurringPattern = recurringPattern
                // Insert fields based on the recurring pattern value
                if (newData.recurringPattern === 'daily' && (newData.repeatsEveryNthDay || newData.selectedDaysOfWeek)) {
                    newData.recurringOptions = {
                        repeatsEveryNthDay: newData.repeatsEveryNthDay,
                        selectedDaysOfWeek: newData.selectedDaysOfWeek
                    }
                } else if (newData.recurringPattern === 'weekly' && newData.repeatsEveryNthWeek && newData.selectedDaysOfWeek) {
                    newData.recurringOptions = {
                        repeatsEveryNthWeek: newData.repeatsEveryNthWeek,
                        selectedDaysOfWeek: newData.selectedDaysOfWeek
                    }
                } else if (newData.recurringPattern === 'monthly' && ((newData.selectedOrdinal && newData.selectedDaysOfWeek && newData.repeatsEveryNthMonth) || (newData.selectedMonthDay && newData.repeatsEveryNthMonthRow2))) {
                    newData.recurringOptions = {
                        selectedDaysOfWeek: newData.selectedDaysOfWeek,
                        selectedOrdinal: newData.selectedOrdinal,
                        selectedMonthDay: newData.selectedMonthDay,
                        repeatsEveryNthMonth: newData.every === "every" ? newData.repeatsEveryNthMonth : newData.repeatsEveryNthMonthRow2,
                    }
                }
            } else {
                // removing unnecessary form data
                delete newData.every
                delete newData.recurringPattern
                delete newData.recurringOptions
                delete newData.repeatsEveryNthDay
                delete newData.repeatsEveryNthWeek
                delete newData.repeatsEveryNthMonth
                delete newData.repeatsEveryNthMonthRow2
                delete newData.selectedDaysOfWeek
                delete newData.selectedDaysOfWeek
                delete newData.selectedMonthDay
                delete newData.selectedOrdinal
                delete newData.selectedYearMonth
            }

            return newData
        })

        if (!slugModified && pageMode === PageMode.ADD) handleTitleChange(values.title)
    }

    const handleSubmit = (values: EventPostFormData) => {
        const { startTime, endTime, publishDate, startDate, endDate, isRecurring } = values
        const newData = { ...values }

        // removing unnecessary form data
        delete newData.repeatsEveryNthDay
        delete newData.repeatsEveryNthMonth
        delete newData.repeatsEveryNthMonthRow2
        delete newData.repeatsEveryNthWeek
        delete newData.selectedDaysOfWeek
        delete newData.selectedDaysOfWeek
        delete newData.selectedMonthDay
        delete newData.selectedOrdinal
        delete newData.selectedYearMonth

        // Destructure newData and omit recurringStartTime and recurringEndTime
        const { recurringStartTime, recurringEndTime, ...newDataWithoutRecurringTimes } = newData;

        const eventPostData: EventPostForApi = {
            ...newDataWithoutRecurringTimes,
            startTime: formatTimeForApi({ IsoTime: isRecurring ? recurringStartTime : startTime }),
            endTime: formatTimeForApi({ IsoTime: isRecurring ? recurringEndTime : endTime }),
            publishDate: formatDateForApi({ date: publishDate }),
            startDate: formatDateForApi({ date: isRecurring ? null : startDate }), // If recurring, set to null
            endDate: formatDateForApi({ date: isRecurring ? null : endDate }), // If recurring, set to null
            notificationType: 'newEvent',
            saveAction: saveAsDraft ? 'draft' : 'create',
        }

        if (pageMode === PageMode.ADD) {
            // create events api call
            createEventApi(newsAndEventsMutation, eventPostData, onSuccess, onError)
        } else {
            // update event api call
            updateEventApi(newsAndEventsMutation, { ...eventPostData, ...selectedPostId }, onSuccess, onError)
        }
    }

    return (
        <>
            {/* toaster component */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />

            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={handleSubmit}
                validate={validate}
                validateOnMount={true}
                validationSchema={() => getValidationSchema({ postType, saveAsDraft, isRecurring, every, recurringPattern })}
            >
                {(
                    { values, errors, touched }
                ) => ( // nosonar
                    <Form>
                        <div className="mb-3">
                            <label className="form-label d-flex">
                                <JTranslation typeCase="pascal" text={HEADING} />
                                <span className="mandatory ">*</span>
                            </label>
                            <Field
                                style={errors.title && touched.title ? { border: '1px solid red' } : undefined}
                                name="title"
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                maxLength="250"
                                data-testid='events-heading'
                            />
                            <ErrorMessage className="formik-error" name="title" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                        </div>
                        <div className="mb-3">
                            <div className="help-small">
                                <label className="form-label"><JTranslation typeCase="pascal" text={TAG} /></label>
                                <MlxPopover data={{title:TAG, body:HELP_EVENT_TAG}}/>
                            </div>
                            <Field
                                style={errors.postTags && touched.postTags ? { border: '1px solid red' } : undefined}
                                name="postTags"
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                maxLength="250"
                                data-testid="events-tags"
                            />
                            <ErrorMessage className="formik-error" name="postTags" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                        </div>
                        <div className="mb-3">
                            <div className="help-small">
                                <label className="form-label"><JTranslation typeCase="pascal" text={LOCATION} /></label>
                                <MlxPopover data={{title:LOCATION, body:HELP_EVENT_LOCATION}}/>
                            </div>
                            <Field
                                style={errors.location && touched.location ? { border: '1px solid red' } : undefined}
                                name="location"
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                maxLength="250"
                                data-testid="events-location"
                            />
                            <ErrorMessage className="formik-error" name="location" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                        </div>
                        <div className="mb-3">
                            <div className="help-small">
                                <label className="form-label d-flex">
                                    <JTranslation typeCase="pascal" text={URL_TEXT} />
                                    <span className="mandatory ">*</span>
                                </label>
                                <MlxPopover data={{title:URL_TEXT, body:HELP_EVENT_URL_TEXT}}/>
                            </div>
                            <Field
                                style={errors.urlSlug && touched.urlSlug ? { border: '1px solid red' } : undefined}
                                name="urlSlug"
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                maxLength="150"
                                data-testid="events-url-text"
                                onChange={handleURLChange}
                            />
                            <ErrorMessage className="formik-error" name="urlSlug" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                        </div>
                        <div className="mb-3">
                            <div className="help-small">
                                <label className="form-label"><JTranslation typeCase="pascal" text={URL_SLUG} /></label>
                                <MlxPopover data={{title:URL_SLUG, body:HELP_EVENT_URL_SLUG}}/>
                            </div>
                            <strong>
                                {formData.urlSlug && `${domainName}${getRouteForSlug()}/${formData.urlSlug}`}
                            </strong>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <label className="form-label"><JTranslation typeCase="pascal" text={DESCRIPTION} /></label>
                            <button type='button' className="btn btn-sm btn-custom-primary-outline" data-testid="ai-text-btn" onClick={() => { setShowAiTextGenerator(!showAiTextGenerator) }}>
                            <i className="ri-ai-generate-text"></i> {AI_TEXT_GENERATOR}
                            </button>
                        </div>

                        <AiTextGenerator
                            key='eventDescription'
                            show={showAiTextGenerator}
                            callBack={(aiResponse: string) => setFormData((prev) => ({ ...prev, description: aiResponse }))}
                            type='eventDescription'
                        />

                        <div className="mb-3">
                            <WysiwygEditorBasic
                                editorContent={unescapeHtml(formData?.description)}
                                data-testid="event-description"
                                callback={(value) => {
                                    setFormData((prev) => ({ ...prev, description: escapeHtml(value) }))
                                }}
                            />
                        </div>

                        {/* RECURRING EVENT - START */}
                        <div className="row mb-3">
                            <div className="col-12">
                                {/* <strong className="fw-bold mb-3">Is Recurring</strong> */}

                                <div className='background-switch-container'>
                                    <div className='d-flex'>
                                        <div className="flex-grow-0">
                                            <div className="icon-container-switch">
                                                <i className="ri-calendar-schedule-line"></i>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 flex-grow-1 mr-2 ps-2 pe-2 d-flex align-items-center justify-content-between text-nowrap overflow-hidden">
                                            <span className='menu-text'>
                                                Recurring Event
                                            </span>
                                            <span className='d-flex mr-3'>
                                                <Field name="isRecurring">
                                                    {({ field, form }: FieldProps) => (
                                                        <InputSwitch
                                                            className='mr-3'
                                                            checked={field.value}
                                                            onChange={(e) => {
                                                                form.setFieldValue(field.name, e.target.value)
                                                                setIsRecurring(e.target.value)
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <div className="custom-bg rounded">
                                    <div className="row p-3">
                                        <div className="col-12">
                                            {
                                                isRecurring
                                                    ? <h6 className="fw-bold mb-3">Event Time</h6>
                                                    : <h6 className="fw-bold mb-3">Event Date and Time</h6>
                                            }

                                        </div>
                                        {!isRecurring ? (
                                            <>
                                                <div className="mb-3 col-md-6">
                                                    <div className="help-small">
                                                        <label className="form-label d-flex">
                                                            <JTranslation typeCase="pascal" text={EVENT_DATE} />
                                                            <span className="mandatory ">*</span>
                                                        </label>
                                                        <MlxPopover data={{title:EVENT_DATE, body:HELP_EVENT_START_DATE}}/>
                                                    </div>
                                                    <Field name="startDate">
                                                        {({ field, form }: FieldProps) => (
                                                            <DatePickerComponent
                                                                selectedDate={field.value}
                                                                minDate={new Date()}
                                                                maxDate={maxDate}
                                                                data-testid="start-date-picker"
                                                                customInput={
                                                                    <input
                                                                        style={
                                                                            errors.startDate && touched.startDate
                                                                                ? { border: '1px solid red' }
                                                                                : undefined
                                                                        }
                                                                        className="form-control"
                                                                        type="text"
                                                                        maxLength={10}
                                                                        autoComplete="off"
                                                                    />
                                                                }
                                                                setDate={(value) => form.setFieldValue(field.name, value)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <ErrorMessage className="formik-error" name="startDate" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <div className="help-small">
                                                        <label className="form-label d-flex">
                                                            <JTranslation typeCase="pascal" text={END_DATE} />
                                                            <span className="mandatory ">*</span>
                                                        </label>
                                                        <MlxPopover data={{title:END_DATE, body:HELP_EVENT_END_DATE}}/>
                                                    </div>
                                                    <Field name="endDate">
                                                        {({ field, form }: FieldProps) => (
                                                            <DatePickerComponent
                                                                selectedDate={field.value}
                                                                minDate={new Date()}
                                                                maxDate={maxDate}
                                                                data-testid="end-date-picker"
                                                                customInput={
                                                                    <input
                                                                        style={
                                                                            errors.endDate && touched.endDate
                                                                                ? { border: '1px solid red' }
                                                                                : undefined
                                                                        }
                                                                        className="form-control"
                                                                        type="text"
                                                                        maxLength={10}
                                                                        autoComplete="off"
                                                                    />
                                                                }
                                                                setDate={(value) => form.setFieldValue(field.name, value)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <ErrorMessage className="formik-error" name="endDate" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <div className="help-small">
                                                        <label className="form-label d-flex">
                                                            <JTranslation typeCase="pascal" text={EVENT_START_TIME} />
                                                            <span className="mandatory ">*</span>
                                                        </label>
                                                        <MlxPopover data={{title:EVENT_START_TIME, body:HELP_EVENT_START_TIME}}/>
                                                    </div>
                                                    <Field name="startTime">
                                                        {({ field, form }: FieldProps) => (
                                                            <TimePicker
                                                                className="form-control"
                                                                popupClassName="custom-antd-time-picker"
                                                                size="middle"
                                                                use12Hours
                                                                format="h:mm a"
                                                                minuteStep={15}
                                                                placeholder="Start Time"
                                                                // Bind the value and onChange handler
                                                                data-testid="start-time-picker"
                                                                value={parseTimeForTimePicker({ formattedTime: field.value })}
                                                                onChange={(value) => form.setFieldValue(field.name, value)}
                                                                style={
                                                                    errors.startTime && touched.startTime
                                                                        ? { border: '1px solid red' }
                                                                        : undefined
                                                                }
                                                                inputReadOnly={true}
                                                            />
                                                        )}
                                                    </Field>
                                                    <ErrorMessage className="formik-error" name="startTime" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <div className="help-small">
                                                        <label className="form-label d-flex">
                                                            <JTranslation typeCase="pascal" text={EVENT_END_TIME} />
                                                            <span className="mandatory ">*</span>
                                                        </label>
                                                        <MlxPopover data={{title:EVENT_END_TIME, body:HELP_EVENT_END_TIME}}/>
                                                    </div>
                                                    <Field name="endTime">
                                                        {({ field, form }: FieldProps) => (
                                                            <TimePicker
                                                                className="form-control"
                                                                popupClassName="custom-antd-time-picker"
                                                                size="middle"
                                                                use12Hours
                                                                format="h:mm a"
                                                                minuteStep={15}
                                                                placeholder="End Time"
                                                                // Bind the value and onChange handler
                                                                data-testid="end-time-picker"
                                                                value={parseTimeForTimePicker({ formattedTime: field.value })}
                                                                onChange={(value) => form.setFieldValue(field.name, value)}
                                                                style={
                                                                    errors.endTime && touched.endTime
                                                                        ? { border: '1px solid red' }
                                                                        : undefined
                                                                }
                                                                inputReadOnly={true}
                                                            />
                                                        )}
                                                    </Field>
                                                    <ErrorMessage className="formik-error" name="endTime" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="mb-3 col-md-6">
                                                    <div className="help-small">
                                                        <label className="form-label d-flex">
                                                            <JTranslation typeCase="pascal" text={EVENT_START_TIME} />
                                                            <span className="mandatory ">*</span>
                                                        </label>
                                                        <MlxPopover data={{title:EVENT_START_TIME, body:HELP_EVENT_START_TIME}}/>
                                                    </div>
                                                    <Field name="recurringStartTime">
                                                        {({ field, form }: FieldProps) => (
                                                            <TimePicker
                                                                className="form-control"
                                                                popupClassName="custom-antd-time-picker"
                                                                size="middle"
                                                                use12Hours
                                                                format="h:mm a"
                                                                minuteStep={15}
                                                                placeholder="Start Time"
                                                                // Bind the value and onChange handler
                                                                data-testid="start-time-picker"
                                                                value={parseTimeForTimePicker({ formattedTime: field.value })}
                                                                onChange={(value) => form.setFieldValue(field.name, value)}
                                                                style={
                                                                    errors.recurringStartTime && touched.recurringStartTime
                                                                        ? { border: '1px solid red' }
                                                                        : undefined
                                                                }
                                                                inputReadOnly={true}
                                                            />
                                                        )}
                                                    </Field>
                                                    <ErrorMessage className="formik-error" name="recurringStartTime" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <div className="help-small">
                                                        <label className="form-label d-flex">
                                                            <JTranslation typeCase="pascal" text={EVENT_END_TIME} />
                                                            <span className="mandatory ">*</span>
                                                        </label>
                                                        <MlxPopover data={{title:EVENT_END_TIME, body:HELP_EVENT_END_TIME}}/>
                                                    </div>
                                                    <Field name="recurringEndTime">
                                                        {({ field, form }: FieldProps) => (
                                                            <TimePicker
                                                                className="form-control"
                                                                popupClassName="custom-antd-time-picker"
                                                                size="middle"
                                                                use12Hours
                                                                format="h:mm a"
                                                                minuteStep={15}
                                                                placeholder="End Time"
                                                                // Bind the value and onChange handler
                                                                data-testid="end-time-picker"
                                                                value={parseTimeForTimePicker({ formattedTime: field.value })}
                                                                onChange={(value) => form.setFieldValue(field.name, value)}
                                                                style={
                                                                    errors.recurringEndTime && touched.recurringEndTime
                                                                        ? { border: '1px solid red' }
                                                                        : undefined
                                                                }
                                                                inputReadOnly={true}
                                                            />
                                                        )}
                                                    </Field>
                                                    <ErrorMessage className="formik-error" name="recurringEndTime" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* FORM COMPONENT */}
                        {isRecurring ? <RecurringTimeForm errors={errors} touched={touched} values={values} nextOccurrence={nextOccurrence} isLoading={isLoading} setNextOccurrence={setNextOccurrence} /> : ""}
                        {/* RECURRING EVENT - END */}

                        <div className="mb-3 col-md-6">
                            <div className="help-small">
                                <label className="form-label d-flex">
                                    <JTranslation typeCase="pascal" text={AUTO_PUBLISH_DATE} />
                                    <span className="mandatory ">*</span>
                                </label>
                                <MlxPopover data={{title:AUTO_PUBLISH_DATE, body:HELP_EVENT_AUTO_PUBLISH_DATE}}/>
                            </div>
                            <Field name="publishDate">
                                {({ field, form }: FieldProps) => (
                                    <DatePickerComponent
                                        selectedDate={field.value}
                                        minDate={new Date()}
                                        maxDate={maxDate}
                                        customInput={
                                            <input
                                                style={
                                                    errors.publishDate && touched.publishDate
                                                        ? { border: '1px solid red' }
                                                        : undefined
                                                }
                                                className="form-control"
                                                type="text"
                                                maxLength={10}
                                                autoComplete="off"
                                                data-testid="event-auto-publish-date"
                                            />
                                        }
                                        setDate={(value) => form.setFieldValue(field.name, value)}
                                    />
                                )}
                            </Field>
                            <ErrorMessage className="formik-error" name="publishDate" component="div" render={(error) => <div className='formik-error'><JTranslation typeCase="none" text={error} /></div>} />
                        </div>

                        <div className="row mb-3">
                            {/* file upload */}
                            <div className="col-md-12  col-lg-12  ">
                                <div className="row img-flex">
                                    <FileUpload
                                        displayToast={displayToast}
                                        onUploadError={onUploadError}
                                        uploadFileMutation={newsAndEventsMutation}
                                        onUploadSuccess={onUploadSuccess}
                                        images={images}
                                        videos={videos}
                                        onDeleteFile={onDeleteFile}
                                        setImages={setImages}
                                        setFormData={setFormData}
                                        formData={formData}
                                        divClass=""
                                        validateImageDimension={validateImageDimension}
                                        cropType={cropType}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* action button */}
                        <div className="save-btn-section shadow save-btn-absolute">
                            <button className="btn btn-custom-primary-outline" type="reset" data-testid="cancel-btn" onClick={() => onClose()}>
                                <JTranslation typeCase="pascal" text={CANCEL} />
                            </button>

                            <button
                                className="btn btn-custom-primary"
                                type="submit"
                                data-testid="save-draft-btn"
                                onClick={() => setSaveAsDraft(true)}
                            >
                                <JTranslation typeCase="pascal" text={SAVE_AS_DRAFT} />
                            </button>

                            <button
                                className="btn btn-custom-secondary"
                                type="submit"
                                data-testid="publish-btn"
                                onClick={() => setSaveAsDraft(false)}
                            >
                                <JTranslation typeCase="pascal" text={submitButtonName} />
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default EventForm
