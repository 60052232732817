import { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AI_TEXT_GENERATOR, ADDITIONAL_INFO, ADD_NEW, ADD_WINE_HISTORY_TITLE, BOUQUET, CANCEL, COLOR, DELETE_WARNING, DENOMINATION, DESCRIPTION, EDIT_WINE_HISTORY_TITLE, ORIGIN, PROCEED, TASTE, TITLE, UPDATE_WARNING, UPLOAD_PHOTO, UPLOAD_PHOTO_MESSAGE } from "../../../../constants/strings";
import { FaPlus } from "react-icons/fa";
import { FileType } from "../../../../constants/constants";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { escapeHtml, htmlToText, isItemUnchanged, unescapeHtml } from "../../../../helpers/utils";
import { initialHistoryData } from "../../../../helpers/menuManagementHelper";
import { MenuItem } from "../../../../constants/staticTypes";
import { Modal } from "react-bootstrap";
import { uploadFile } from "../../../../helpers/fileUploadHelper";
import { useQueryClient } from "react-query";
import AiTextGenerator from "../../../ai_text_generator/AiTextGenerator";
import countries from "../../../../assets/files/countrylist.json";
import Select from "react-select";
import useMutationHook from "../../../../hooks/useMutationHook";
import WarningModal from "../../../warning_modal/WarningModal";
import WysiwygEditor from "../../../wysiwyg_editor/WysiwygEditor";
import { JTranslation } from "../../../../helpers/jTranslate";

type Props = {
    show: boolean,
    onHide: React.Dispatch<React.SetStateAction<boolean>>,
    formData: MenuItem,
    setFormData: React.Dispatch<React.SetStateAction<MenuItem>>,
    displayToast: (message: string, variant: string) => void,
    onUploadError: (fileType: FileType) => void,
}

enum WarningCallback {
    DEL_PHOTO = "delete", DEL_ADDINFO = "additional-info", QUIT = "cancel"
}

function AddWineHistoryForm({ show, onHide, formData, setFormData, onUploadError, displayToast }: Props) {
    const [addInfoRow, setAddInfoRow] = useState([0]);
    const queryClient = useQueryClient();
    const uploadMutation = useMutationHook(queryClient, true);
    const [historyInfo, setHistoryInfo] = useState(initialHistoryData);
    const [historyInfoInitial, setHistoryInfoInitial] = useState(initialHistoryData);
    const [warning, showWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [warningType, setCallbackType] = useState(WarningCallback.QUIT);
    const [deleteIndex, setDeleteIndex] = useState(0);
    const [parentIndex, setParentIndex] = useState(0);
    const [isEdit, setEdit] = useState(false);
    const [showAiDescriptionGenerator, setShowAiDescriptionGenerator] = useState(false)
    const [showAiTextGenerator, setShowAiTextGenerator] = useState([false])

    // set form data
    const setForm = () => {
        const history = formData.historyInfo;
        setHistoryInfo({
            ...history,
            additionalInfo: [...history.additionalInfo]
        });
        setHistoryInfoInitial({
            ...history,
            additionalInfo: [...history.additionalInfo]
        });
    }

    // set history info with form data on show
    useEffect(() => {
        setForm();
        if (formData.historyInfo.title &&
            formData.historyInfo.title !== null &&
            formData.historyInfo.title?.trim().length > 0) {
            setEdit(true);
        }
    }, [formData.historyInfo, show])

    // update add row info array on delete items
    useEffect(() => {
        let newAddRow = historyInfo.additionalInfo.map((_item, index) => index);
        setAddInfoRow(newAddRow);
    }, [historyInfo.additionalInfo]);

    // upload photo success
    const onUploadSuccess = (key: string, imageUrl: string, _fileType: FileType) => {
        setHistoryInfo({
            ...historyInfo,
            photoKeys: [...historyInfo.photoKeys, key],
            signedPhotoKeys: [...historyInfo.signedPhotoKeys, imageUrl]
        });
    }

    // custom search for country
    const customSearch = (option: FilterOptionOption<{
        value: string;
        label: string;
    }>, inputValue: string) => {
        if (inputValue?.trim.length > 1) {
            return option.value.toLowerCase().includes(inputValue.toLowerCase());
        } else {
            return option.value.toLowerCase().startsWith(inputValue.toLowerCase());
        }
    }

    return (
        <>
            <WarningModal show={warning} title={warningMessage} onHide={() => showWarning(!warning)} callback={() => {
                switch (warningType) {
                    case WarningCallback.DEL_PHOTO:
                        historyInfo.signedPhotoKeys.splice(deleteIndex, 1);
                        historyInfo.photoKeys.splice(deleteIndex, 1);
                        setHistoryInfo({
                            ...historyInfo,
                            photoKeys: historyInfo.photoKeys,
                            signedPhotoKeys: historyInfo.signedPhotoKeys
                        });
                        break;

                    case WarningCallback.DEL_ADDINFO:
                        historyInfo.additionalInfo[parentIndex].signedImageKeys.splice(deleteIndex, 1);
                        historyInfo.additionalInfo[parentIndex].imageKeys.splice(deleteIndex, 1);
                        setHistoryInfo({
                            ...historyInfo,
                            additionalInfo: historyInfo.additionalInfo
                        });
                        break;

                    default:
                        setForm();
                        onHide(!show);
                        break;
                }
                showWarning(false);
            }} />

            <Modal show={show}
                size="lg"
                onHide={() => onHide(!show)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg" className="modal-custom-title">
                        {isEdit
                            ? <JTranslation typeCase="pascal" text={EDIT_WINE_HISTORY_TITLE} />
                            : <JTranslation typeCase="pascal" text={ADD_WINE_HISTORY_TITLE} />
                        }
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12  mb-3">
                            <label htmlFor="history-title" className="form-label"><JTranslation typeCase="pascal" text={TITLE} /><span className="mandatory ">*</span></label>
                            <input type="text" className="form-control" id="history-title" data-testid="history-title"
                                autoComplete="off"
                                value={historyInfo.title}
                                maxLength={100}
                                onChange={(e) => setHistoryInfo({
                                    ...historyInfo, title: e.target.value
                                })} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12  mb-3">
                            <label htmlFor="" className="form-label"><JTranslation typeCase="pascal" text={"Sub Title"} /></label>
                            <input type="text" className="form-control" id="" data-testid="history-subtitle"
                                autoComplete="off"
                                value={historyInfo.subTitle}
                                maxLength={500}
                                onChange={(e) => setHistoryInfo({
                                    ...historyInfo, subTitle: e.target.value
                                })} />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <label><JTranslation typeCase="pascal" text={DESCRIPTION} /><span className="mandatory ">*</span></label>
                        <button className="btn btn-sm btn-custom-primary-outline" data-testid="ai-text-button" onClick={() => {
                            setShowAiDescriptionGenerator(!showAiDescriptionGenerator)
                        }}

                        >
                           <i className="ri-ai-generate-text"></i> <JTranslation typeCase="pascal" text={AI_TEXT_GENERATOR} />
                        </button>
                    </div>
                    <AiTextGenerator
                        key='wineHistory'
                        show={showAiDescriptionGenerator}
                        callBack={(aiResponse: string) => {
                            setHistoryInfo({ ...historyInfo, description: aiResponse })
                        }}
                        type='wineHistory'
                    />

                    <div className="row">
                        <div className="col-lg-12 mb-3">

                            <WysiwygEditor editorContent={unescapeHtml(historyInfo?.description)}
                                data-testid="history-desc" callback={(value) => setHistoryInfo({
                                    ...historyInfo,
                                    description: escapeHtml(value)
                                })} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6  mb-3">
                            <label htmlFor="history-origin" className="form-label"><JTranslation typeCase="pascal" text={ORIGIN} /></label>
                            <Select filterOption={customSearch} data-testid="origin-select" options={countries.map(country => {
                                return { value: country.name, label: country.name };
                            })} isSearchable
                                onChange={(selected => {
                                    if (selected) {
                                        setHistoryInfo({
                                            ...historyInfo,
                                            originCountry: selected?.value
                                        });
                                    }
                                })}
                                value={{ value: historyInfo.originCountry, label: historyInfo.originCountry }} />
                        </div>
                        <div className="col-md-6  mb-3">
                            <label htmlFor="history-color" className="form-label"><JTranslation typeCase="pascal" text={COLOR} /></label>
                            <input type="text" className="form-control" id="history-color"
                                autoComplete="off"
                                data-testid="color-input"
                                value={historyInfo.color}
                                maxLength={100}
                                onChange={(e) => {
                                    setHistoryInfo({ ...historyInfo, color: e.target.value })
                                }} />
                        </div>
                        <div className="col-md-6  mb-3">
                            <label htmlFor="history-bouqet" className="form-label"><JTranslation typeCase="pascal" text={BOUQUET} /></label>
                            <input type="text" className="form-control" id="history-bouqet"
                                autoComplete="off"
                                data-testid="bouqet-input"
                                value={historyInfo.bouquet}
                                maxLength={100}
                                onChange={(e) => setHistoryInfo({ ...historyInfo, bouquet: e.target.value })} />
                        </div>
                        <div className="col-md-6  mb-3">
                            <label htmlFor="history-taste" className="form-label"><JTranslation typeCase="pascal" text={TASTE} /></label>
                            <input type="text" className="form-control" id="history-taste"
                                autoComplete="off"
                                data-testid="taste-input"
                                value={historyInfo.taste}
                                maxLength={100}
                                onChange={(e) => setHistoryInfo({ ...historyInfo, taste: e.target.value })} />
                        </div>
                        <div className="col-md-6  mb-3">
                            <label htmlFor="history-denomination" className="form-label"><JTranslation typeCase="pascal" text={DENOMINATION} /></label>
                            <input type="text" className="form-control" id="history-denomination"
                                autoComplete="off"
                                data-testid="denomination-input"
                                value={historyInfo.denomination}
                                maxLength={200}
                                onChange={(e) => setHistoryInfo({ ...historyInfo, denomination: e.target.value })} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="mb-3 col-md-12  col-lg-6 d-flex flex-column upload-c">
                            <label htmlFor="history-upload" className={`custom-file-upload btn  btn-custom-primary-outline w-100 
                            ${historyInfo.signedPhotoKeys.length > 0 && " disabled"}`}>
                                <i className="ri-upload-line"></i> <JTranslation typeCase="pascal" text={UPLOAD_PHOTO} />
                            </label>
                            <input data-testid="history-upload" id="history-upload" type="file" accept="image/*" autoComplete="off"
                                disabled={historyInfo.signedPhotoKeys.length > 0} onChange={(e) => {
                                    uploadFile(FileType.IMAGE, e, uploadMutation, onUploadSuccess, onUploadError, displayToast);
                                }} />
                            <div className="mt-2 small text-secondary"><JTranslation typeCase="pascal" text={UPLOAD_PHOTO_MESSAGE} /></div>
                        </div>

                        <div className="mb-3 col-md-12  col-lg-6 img-flex d-flex align-items-start">
                            {historyInfo.signedPhotoKeys.map((image, index) => {
                                return <div className="img-thumb-main-small d-flex align-items-center justify-content-center mb-2">
                                    <div className="delete" onClick={() => {
                                        setDeleteIndex(index);
                                        setCallbackType(WarningCallback.DEL_PHOTO);
                                        setWarningMessage(DELETE_WARNING);
                                        showWarning(true);
                                    }}>
                                        <i className="ri-delete-bin-6-line"></i>
                                    </div>
                                    <img key={image} src={image} className="" alt=" " />
                                </div>
                            })}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 mb-3">
                            <div className="d-flex align-items-center border-bottom pb-2">
                                <div className="flex-grow-1">
                                    <label className="fw-bold "><JTranslation typeCase="pascal" text={ADDITIONAL_INFO} /></label>
                                </div>

                                <div className="flex-grow-0">
                                    <button className="btn-transparent btn-additional-info" data-testid="add-new-btn" onClick={() => {
                                        setAddInfoRow([...addInfoRow, addInfoRow.length]);
                                        setHistoryInfo({
                                            ...historyInfo,
                                            additionalInfo: [
                                                ...historyInfo.additionalInfo,
                                                {
                                                    textContent: "",
                                                    imageKeys: [],
                                                    signedImageKeys: []
                                                }
                                            ]
                                        });
                                        setShowAiTextGenerator([...showAiTextGenerator, false])
                                    }}><FaPlus /> <JTranslation typeCase="pascal" text={ADD_NEW} /></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {addInfoRow.map((_row, index) => {
                        return <div key={index}>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <label><JTranslation typeCase="pascal" text={DESCRIPTION} /></label>
                                <button className="btn btn-sm btn-custom-primary-outline" data-testid="ai-text-btn" onClick={() => {
                                    setShowAiTextGenerator((prevValue) => {
                                        // Copy the existing array without the value at the specific index
                                        const array = [...prevValue.slice(0, index), ...prevValue.slice(index + 1)];
                                        // Insert the toggled value at the index
                                        array.splice(index, 0, !prevValue[index]);
                                        return array;
                                    });
                                }}

                                >
                                  <i className="ri-ai-generate-text"></i>  <JTranslation typeCase="pascal" text={AI_TEXT_GENERATOR} />
                                </button>
                            </div>
                            <AiTextGenerator
                                key='menuAdditionalInfo'
                                show={showAiTextGenerator[index]}
                                callBack={(aiResponse: string) => {
                                    let imageKeys = historyInfo.additionalInfo[index].imageKeys;
                                    let signedImageKeys = historyInfo.additionalInfo[index].signedImageKeys;
                                    historyInfo.additionalInfo.splice(index, 1, {
                                        textContent: aiResponse,
                                        imageKeys: imageKeys,
                                        signedImageKeys: signedImageKeys
                                    });
                                    setHistoryInfo({ ...historyInfo, additionalInfo: historyInfo.additionalInfo });
                                }}
                                type='menuAdditionalInfo'
                            />
                            <div className="row">
                                <div className="col-lg-12 mb-3">

                                    <div className="d-flex align-items-center justify-content-center wine-ad-info rounded-1">
                                        <div className="flex-grow-1 width-0">
                                            <WysiwygEditor editorContent={unescapeHtml(historyInfo.additionalInfo[index]?.textContent)}
                                                data-testid={"history-desc#" + index}
                                                callback={(value) => {
                                                    let imageKeys = historyInfo.additionalInfo[index].imageKeys;
                                                    let signedImageKeys = historyInfo.additionalInfo[index].signedImageKeys;
                                                    historyInfo.additionalInfo.splice(index, 1, {
                                                        textContent: escapeHtml(value),
                                                        imageKeys: imageKeys,
                                                        signedImageKeys: signedImageKeys
                                                    });
                                                    setHistoryInfo({ ...historyInfo, additionalInfo: historyInfo.additionalInfo });
                                                }} />
                                        </div>
                                        {index > 0 && <div className="flex-grow-0 text-danger wine-ad-info-delete p-2 ms-2 rounded-circle"
                                            onClick={() => {
                                                historyInfo.additionalInfo.splice(index, 1);
                                                addInfoRow.splice(index, 1);
                                                setAddInfoRow(addInfoRow);
                                                setHistoryInfo({ ...historyInfo, additionalInfo: historyInfo.additionalInfo })
                                            }}><i className="ri-delete-bin-6-line"></i></div>}
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="mb-3 col-md-12  col-lg-6 d-flex flex-column upload-c">
                                    <label htmlFor={"file-upload#" + index} className="custom-file-upload btn  btn-custom-primary-outline w-100 " >
                                    <i className="ri-upload-line"></i> <JTranslation typeCase="pascal" text={UPLOAD_PHOTO} />
                                    </label>
                                    <input data-testid={"file-upload#" + index} id={"file-upload#" + index} type="file" accept="image/*" autoComplete="off"
                                        onChange={(e) => {
                                            uploadFile(FileType.IMAGE, e, uploadMutation, (key, fileUrl, _fileType) => { // on success
                                                let textContent = historyInfo.additionalInfo[index].textContent;
                                                historyInfo.additionalInfo.splice(index, 1, {
                                                    textContent: textContent,
                                                    imageKeys: [...historyInfo.additionalInfo[index].imageKeys, key],
                                                    signedImageKeys: [...historyInfo.additionalInfo[index].signedImageKeys, fileUrl]
                                                });
                                                setHistoryInfo({
                                                    ...historyInfo,
                                                    additionalInfo: historyInfo.additionalInfo
                                                });
                                            }, onUploadError, displayToast);
                                        }} />
                                    <div className="mt-2 small text-secondary"><JTranslation typeCase="capitalize" text={UPLOAD_PHOTO_MESSAGE} /></div>
                                </div>

                                <div className="mb-3 col-md-12  col-lg-6 img-flex d-flex align-items-start">
                                    {historyInfo.additionalInfo[index]?.signedImageKeys.map((image, index2) => {
                                        return <div className="img-thumb-main-small d-flex align-items-center justify-content-center mb-2">
                                            <div className="delete" onClick={() => {
                                                setParentIndex(index);
                                                setDeleteIndex(index2);
                                                setWarningMessage(DELETE_WARNING);
                                                setCallbackType(WarningCallback.DEL_ADDINFO);
                                                showWarning(true);
                                            }}>
                                                <i className="ri-delete-bin-6-line"></i>
                                            </div>
                                            <img key={image} src={image} className="" alt=" " />
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    })}
                </Modal.Body>
                <Modal.Footer className="save-btn-section">
                    <button className="btn btn-custom-primary-outline " data-testid="cancel" onClick={() => {
                        if (isItemUnchanged(historyInfoInitial, historyInfo)) {
                            setForm();
                            onHide(!show);
                        } else {
                            setWarningMessage(UPDATE_WARNING);
                            setCallbackType(WarningCallback.QUIT);
                            showWarning(true);
                        }
                    }}> <JTranslation typeCase="pascal" text={CANCEL} /></button>
                    <button className="btn btn-custom-primary"
                        disabled={historyInfo.title?.trim().length === 0 || htmlToText(historyInfo.description)?.trim().length === 0}
                        data-testid="proceed"
                        onClick={() => {
                            setFormData({ ...formData, historyInfo });
                            onHide(!show)
                        }}> <JTranslation typeCase="pascal" text={PROCEED} /></button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddWineHistoryForm;