import { useEffect, useState } from "react";
import { ADDITIONAL_INFO, DELETE_WARNING, HELP_ADDITIONAL_INFO, AI_TEXT_GENERATOR } from "../../../../constants/strings";
import { FaPlus } from "react-icons/fa";
import { MenuItem, ToastMessageProps } from "../../../../constants/staticTypes";
import { OverlayTrigger } from "react-bootstrap";
import { escapeHtml, popover, unescapeHtml } from '../../../../helpers/utils';
import { toastMessageInitialData } from "../../../../constants/constants";
import AiTextGenerator from "../../../ai_text_generator/AiTextGenerator";
import ToastAlert from "../../../alert/ToastAlert";
import WarningModal from "../../../warning_modal/WarningModal";
import WysiwygEditor from "../../../wysiwyg_editor/WysiwygEditor";
import { JTranslation } from "../../../../helpers/jTranslate";
import MlxPopover from "../../../common/MlxPopover";

type Props = {
    formData: MenuItem,
    setFormData: React.Dispatch<React.SetStateAction<MenuItem>>,
}

function AdditionalInfo({ formData, setFormData }: Props) {
    const [addInfoRow, setAddInfoRow] = useState([0]);
    const [warning, showWarning] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(0);
    const [showAiTextGenerator, setShowAiTextGenerator] = useState([false])
    // ALERT VARIABLES
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)

    useEffect(() => {
        let newAddInfoRow = formData.additionalInfo.map((_item, index) => {
            return index;
        });
        setAddInfoRow(newAddInfoRow);
    }, [formData.additionalInfo]);

    return <>
        <WarningModal show={warning} onHide={() => showWarning(false)} title={DELETE_WARNING} callback={() => {
            formData.additionalInfo.splice(deleteIndex, 1);
            addInfoRow.splice(deleteIndex, 1);
            setAddInfoRow(addInfoRow);
            setFormData({
                ...formData, additionalInfo: formData.additionalInfo
            });
            showWarning(false);
        }} />

        <ToastAlert
            data-testid="toast"
            message={toastMessage.message}
            onClose={() => setToastMessage(toastMessageInitialData)}
            show={toastMessage.show}
            variant={toastMessage.variant}
        />

        {addInfoRow.map((_row, index) => {
            return <div key={index}>
                <div className="row ">
                    <div className="col-lg-12">

                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-lg-12 mb-2">
                        <div className="d-flex align-items-center justify-content-center wine-ad-info rounded-1">
                            <div className="flex-grow-1 width-0 wysiwyg-editor">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <label className="d-flex align-items-center"><JTranslation typeCase="pascal" text={ADDITIONAL_INFO} />
                                            <div className="help-small">
                                                <MlxPopover data={{title:ADDITIONAL_INFO, body:HELP_ADDITIONAL_INFO}}/>
                                            </div>
                                        </label>
                                    </div>

                                    <div className="flex-grow-0 align-items-center mb-2">

                                        <button className="btn btn-sm btn-custom-primary-outline"
                                            data-testid="aiTextBtn"
                                            onClick={() => {
                                                setShowAiTextGenerator((prevValue) => {
                                                    // Copy the existing array without the value at the specific index
                                                    const array = [...prevValue.slice(0, index), ...prevValue.slice(index + 1)];
                                                    // Insert the toggled value at the index
                                                    array.splice(index, 0, !prevValue[index]);
                                                    return array;
                                                });
                                            }}

                                        >
                                            <i className="ri-ai-generate-text"></i> <JTranslation typeCase="pascal" text={AI_TEXT_GENERATOR} />
                                        </button>
                                    </div>

                                </div>
                                <AiTextGenerator
                                    key='menuAdditionalInfo'
                                    show={showAiTextGenerator[index]}
                                    callBack={(aiResponse: string) => {
                                        let imageKeys = formData.additionalInfo[index].imageKeys;
                                        let signedImageKeys = formData.additionalInfo[index].signedImageKeys;
                                        formData.additionalInfo.splice(index, 1, {
                                            textContent: aiResponse,
                                            imageKeys: imageKeys,
                                            signedImageKeys: signedImageKeys
                                        });
                                        setFormData({ ...formData, additionalInfo: formData.additionalInfo })
                                    }}
                                    type='menuAdditionalInfo'
                                />
                                <WysiwygEditor editorContent={unescapeHtml(formData.additionalInfo[index]?.textContent)}
                                    data-testid="additionalInfoField"
                                    callback={(value) => {
                                        let imageKeys = formData.additionalInfo[index].imageKeys;
                                        let signedImageKeys = formData.additionalInfo[index].signedImageKeys;
                                        formData.additionalInfo.splice(index, 1, {
                                            textContent: escapeHtml(value),
                                            imageKeys: imageKeys,
                                            signedImageKeys: signedImageKeys
                                        });
                                        setFormData({ ...formData, additionalInfo: formData.additionalInfo })
                                    }} />
                            </div>
                            {index > 0 && <div className="flex-grow-0 text-danger wine-ad-info-delete p-2 ms-2 rounded-circle"
                                onClick={() => {
                                    setDeleteIndex(index);
                                    showWarning(true);
                                }}><i className="ri-delete-bin-6-line"></i></div>}
                        </div>
                    </div>
                    <div className="col-12 mb-4">
                        {index === 0 && <div className="flex-grow-0 d-flex justify-content-start">
                            <span className="additional-info-link"
                                data-testid="addAdditionalInfoBtn"
                                onClick={() => {
                                    setFormData({
                                        ...formData, additionalInfo: [...formData.additionalInfo, {
                                            textContent: "",
                                            imageKeys: [],
                                            signedImageKeys: []
                                        }]
                                    });
                                    setAddInfoRow([...addInfoRow, addInfoRow.length]);
                                    setShowAiTextGenerator([...showAiTextGenerator, false])
                                }}><i className="ri-add-line"></i> <JTranslation typeCase="pascal" text={ADDITIONAL_INFO} /></span>
                        </div>}
                    </div>
                </div>
            </div>
        })}
    </>
}

export default AdditionalInfo;
