import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useContext, useEffect, useState } from "react";
import { Survey, SurveyReport } from "../../constants/staticTypes";
import {
    DATE_OF_VISIT, EMAIL_TEXT,
    FULL_NAME,
    MOBILE_TEXT,
    NO_OF_VISIT,
    NO_SURVEY_REPORTS, SERVER_NAME, SURVEY_DETAILS, VIEW_SURVEY
} from "../../constants/strings";
import { convertUTCtoLocalByOffset, getCustomPaginator, toPascalCase } from "../../helpers/utils";
import SurveyReportViewModal from "./SurveyReportViewModal";
import { JTranslation, TranslationContext, jTranslationText } from "../../helpers/jTranslate";
import { useWindowSize } from "react-use";
import { MOBILE_VIEW_BREAKPOINT } from "../../constants/constants";
import { Card } from "primereact/card";
import { Badge } from "primereact/badge";
import { DataView } from "primereact/dataview";
import MlxTooltip from "../common/MlxTooltip";
import { capitalize } from "lodash";

type Props = {
    searchValue: string,
    lastKey: string | null,
    surveyReports: SurveyReport[],
    onSearchTrigger: (searchKey: string, lastKeyValue: string | null) => void,
    setPagination: React.Dispatch<React.SetStateAction<boolean>>,
    isLoading: boolean,
    selectedPage: number,
    setSelectedPage: React.Dispatch<React.SetStateAction<number>>
}

function SurveyReportGrid({ surveyReports, onSearchTrigger, lastKey, searchValue, setPagination, isLoading,
    selectedPage, setSelectedPage }: Props) {
    const [showReport, setReportView] = useState(false);
    const [surveyReportData, setSurveyReport] = useState<SurveyReport>({} as SurveyReport);

    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext

    const [translationText, setTranslatedText] = useState<{
        paginationTemplate: string,
        emptyMessage: string,
    }>({ 
        paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
        emptyMessage: NO_SURVEY_REPORTS,
    });

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: NO_SURVEY_REPORTS, typeCase: 'pascal', translationContext }),
                ])
                
                setTranslatedText({
                    paginationTemplate:
                        translations[0]
                            ?.replace('1', '{first}')
                            ?.replace('2', '{last}')
                            ?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: translations[1] ?? NO_SURVEY_REPORTS,
                })
            } catch {
                setTranslatedText({
                    paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
                    emptyMessage: NO_SURVEY_REPORTS,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    const emailBodyTemplate = (surveyReport: SurveyReport) => {
        return <MlxTooltip target='.email-text'> 
            <a className="email-text" data-pr-position="bottom" data-testid={"guest-email#" + surveyReport.id} data-pr-tooltip={surveyReport.guestUserData.emailId} href={"mailto:" + surveyReport.guestUserData.emailId}>
            {surveyReport.guestUserData.emailId}</a>
        </MlxTooltip>
    }

    const surveyViewButtonTemplate = (surveyReport: SurveyReport) => {
        return <button type="button" className="btn btn-success" data-testid={`view-survey-${surveyReport.id}`}
            onClick={() => {
                setSurveyReport(surveyReport);
                setReportView(true);
            }}><JTranslation typeCase="pascal" text={VIEW_SURVEY} /></button>;
    }

    const serverNameTemplate = (surveyReport: SurveyReport) => {
        if (surveyReport.serverName !== null) {
            const serverData = `${capitalize(surveyReport?.serverName ?? '')}`;
            return <MlxTooltip target='.server-data'>
                <div data-pr-position="bottom" data-pr-tooltip={serverData}
                    className="flex-grow-1 overflow-ellipsis server-data"
                >
                    {serverData}
                </div>
            </MlxTooltip>
        }
    }

    const getDate = (date: number) => (<>{convertUTCtoLocalByOffset(date)}</>)

    const itemTemplate = (report: SurveyReport) => {
        return (
			<Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="m-0">{toPascalCase(report.guestUserData.fullName)}</p>
                    <span className="d-flex align-items-center">
                        <span className="small-text">
                            <JTranslation typeCase="pascal" text={'Visits'} />
                        </span>
                        <Badge value={report.guestUserData.totalVisitCount} className="ms-2" />
                    </span>
                </div>
				<div className='mt-2'>
                    <div className="d-flex flex-row align-items-center">
					    <p className="m-0 font-bold">
                            {emailBodyTemplate(report)}{report.guestUserData.phoneNumber ? ', ' : ''}
                            {report.guestUserData.phoneNumber}
                        </p>
                    </div>
                    {report.serverName?.length > 0 && 
                        <div className="d-flex flex-row align-items-center">
                            <span className="me-2"><JTranslation typeCase="capitalize" text={SERVER_NAME} />:</span>
                            <p className="m-0 font-bold">{report.serverName}</p>
                        </div>
                    } 
                    {!!report.createdAt && 
                        <div className="d-flex flex-row align-items-center">
                            <span className="me-2"><JTranslation typeCase="capitalize" text={DATE_OF_VISIT} />:</span>
                            <p className="m-0 font-bold">{getDate(report.createdAt)}</p>
                        </div>
                    }                 
				</div>
				<div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
					<div className='d-flex justify-content-even align-items-center'></div>
					<div className='d-flex justify-content-even align-items-center'>{surveyViewButtonTemplate(report)}</div>
				</div>
			</Card>
		)
    }

    return <>
        <SurveyReportViewModal show={showReport} handleClose={() => setReportView(false)} surveyReport={surveyReportData} />

        {width <= breakPoint ? (
            <DataView
                value={surveyReports}
                layout={'list'}
                itemTemplate={itemTemplate}
                paginator
                rows={10}
                paginatorClassName="p-dataview-customers"
                dataKey="id"
                emptyMessage={translationText.emptyMessage}
            />
            ) : (
                <DataTable value={surveyReports} paginator className="p-datatable-customers" rows={10} data-testid="report-grid"
                    pageLinkSize={3} first={selectedPage} onPage={(e) => { setSelectedPage(e.first) }}
                    scrollable scrollHeight="flex"
                    paginatorTemplate={getCustomPaginator(setPagination, onSearchTrigger, surveyReports.length, lastKey, searchValue)}
                    dataKey="id" rowHover responsiveLayout="scroll"
                    emptyMessage={translationText.emptyMessage}
                    loading={isLoading}
                    currentPageReportTemplate={translationText.paginationTemplate}>
                    <Column field="createdAt" header={<JTranslation typeCase="pascal" text={DATE_OF_VISIT} />} sortable style={{ minWidth: '10rem', maxWidth: '10rem' }}
                        body={(questionSet: Survey) => {
                            if (questionSet.createdAt) {
                                return (getDate(questionSet.createdAt))
                            }
                            return "";
                        }} />
                    <Column field="guestUserData.fullName" header={<JTranslation typeCase="pascal" text={FULL_NAME} />} sortable className="word-break-grid" style={{ minWidth: '17rem' }
                    } body={(data: SurveyReport) => toPascalCase(data?.guestUserData?.fullName ?? '')} />
                    <Column field="guestUserData.phoneNumber" sortable header={<JTranslation typeCase="pascal" text={MOBILE_TEXT} />} style={{ maxWidth: '8rem', minWidth: '8rem' }} />
                    <Column field="guestUserData.emailId" className="overflow-ellipsis" header={<JTranslation typeCase="pascal" text={EMAIL_TEXT} />} sortable style={{ maxWidth: '15rem', minWidth: '15rem' }}
                        body={emailBodyTemplate} />
                    <Column field="serverName" header={<JTranslation typeCase="pascal" text={SERVER_NAME} />} sortable style={{ maxWidth: '17rem', minWidth: '10rem' }}
                        body={serverNameTemplate} />
                    <Column field="guestUserData.totalVisitCount" sortable header={<JTranslation typeCase="pascal" text={NO_OF_VISIT} />} style={{ maxWidth: '8rem', minWidth: '8rem', alignItems: 'center', justifyContent: 'center' }} />
                    <Column header={<JTranslation typeCase="pascal" text={SURVEY_DETAILS} />} className="td-center" style={{ maxWidth: '12rem', minWidth: '10rem' }}
                        body={surveyViewButtonTemplate} bodyClassName="bg-sticky" alignFrozen="right" frozen={true} />
                </DataTable >
            )
        }
    </>
}

export default SurveyReportGrid;