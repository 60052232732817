import { OverlayTrigger } from 'react-bootstrap'
import { defaultPagination, defaultPaginationEvent, popover } from '../../helpers/utils'
import {
    DELETE_WARNING,
    AVAILABILITY_REQUESTS,
    AVAILABILITY_REQUESTS_HELP
} from '../../constants/strings'
import withSidebar from '../../hoc/withSidebar'
import WarningModal from '../../components/warning_modal/WarningModal'
import ToastAlert from '../../components/alert/ToastAlert'
import {
    AlertVariant,
    MOBILE_VIEW_BREAKPOINT,
    PageMode, toastMessageInitialData
} from '../../constants/constants'
import { useContext, useEffect, useRef, useState } from 'react'
import {
    AddAvailabilityResponse,
    AvailabilityData, DatatablePagEvent, PaginationApi, SingleAvailabilityFormData,
    ToastMessageProps
} from '../../constants/staticTypes'
import { CommonCtx } from '../../context/CommonCtxProvider'
import AvailabilitySideBar from '../../components/availability/AvailabilitySideBar'
import { FaSearch } from 'react-icons/fa'
import { useQueryClient } from 'react-query'
import { DebounceInput } from 'react-debounce-input'
import { AxiosResponse } from 'axios'
import {
    currentWeekDaysString,
    deleteStaffAvailability,
    getAllAvailabilityOfUsers
} from '../../helpers/availabilityHelper'
import useQueryHook from '../../hooks/useQueryHook'
import Loader from '../../components/loader/Loader'
import { AVAILABILITY_REQUESTS_LIST } from '../../constants/queryKeys'
import useMutationHook from '../../hooks/useMutationHook'
import AllAvailabilityDataGrid from '../../components/availability/AllAvailabilityDataGrid'
import { useWindowSize } from 'react-use'
import MlxPopover from '../../components/common/MlxPopover'

function AvailabilityList() {
	const queryClient = useQueryClient()
	const availabilityMutation = useMutationHook(queryClient, true)
	const currentWeek = currentWeekDaysString()

	// STATE VARIABLE
	const [mode, setMode] = useState<PageMode>(PageMode.ADD)
	const [availabilityType, setAvailabilityType] = useState<'REGULAR' | 'TEMPORARY'>('REGULAR')
	const [allAvailabilityList, setAllAvailabilityList] = useState<AvailabilityData[]>([])
	const [deleteAvailability, setDeleteAvailability] = useState<SingleAvailabilityFormData>({}) // delete availability
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
	const [showWarning, setWarning] = useState(false) // Warning popup
	const [warningMessage, setWarningMessage] = useState('') // Warning message
	const [dataTableEvent, setDataTableEvent] = useState<DatatablePagEvent>(defaultPaginationEvent);
    const [paginationData, setPaginationData] = useState<PaginationApi>(defaultPagination);

	// CONTEXT VARIABLE
	const { showSideBar, setShowSideBar } = useContext(CommonCtx)

	const [showTitle, setShowTitle] = useState(true)
    const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

	// availability list fetch success
	const onAvailabilityListFetchSuccess = (res: AxiosResponse<any, any>) => {
		const availabilityListData = (res.data.data.lists as AvailabilityData[]) || ([] as AvailabilityData[])
		setAllAvailabilityList(availabilityListData)

		const paginationData = res?.data?.data?.pagination as PaginationApi;
		setPaginationData(paginationData);
	}

	// fetch availability list
	const availabilityListData = useQueryHook(
		AVAILABILITY_REQUESTS_LIST,
		() => {
			return getAllAvailabilityOfUsers((dataTableEvent.page ?? 0) + 1, dataTableEvent.rows)
		},
		onAvailabilityListFetchSuccess
	)
	const availabilityListDataQueryRef = useRef(availabilityListData);

	const onAvailabilityDeleteClick = (availability: AvailabilityData) => {
		setDeleteAvailability({ id: availability.id })
		setWarningMessage(DELETE_WARNING)
		setWarning(true)
	}

	const deleteAvailabilityApiCall = () => {
		deleteStaffAvailability(availabilityMutation, deleteAvailability, onSuccess, onError)
	}

	const onSuccess = (res: AddAvailabilityResponse) => {
		queryClient.refetchQueries([AVAILABILITY_REQUESTS_LIST])
		setShowSideBar(false)
		setToastMessage({ message: res.message, show: true, variant: AlertVariant.SUCCESS })
	}

	const onError = (message: string) => {
		setToastMessage({ message: message, show: true, variant: AlertVariant.ERROR })
	}

	useEffect(() => {
        availabilityListDataQueryRef.current.refetch();
    }, [dataTableEvent?.forceUpdate]);

	return (
		<>
			<WarningModal
				show={showWarning}
				title={warningMessage}
				onHide={() => {
					setWarning(false)
					setDeleteAvailability({})
				}}
				callback={() => {
					setWarning(false)
					deleteAvailabilityApiCall()
				}}
			/>

			<ToastAlert
				data-testid="toast"
				message={toastMessage.message}
				onClose={() => setToastMessage(toastMessageInitialData)}
				show={toastMessage.show}
				variant={toastMessage.variant}
			/>

			{/* availability sidebar */}
			<AvailabilitySideBar
				availabilityType={availabilityType}
				pageMode={mode}
				setMode={setMode}
				setShowSideBar={setShowSideBar}
				setToastMessage={setToastMessage}
			/>

			<div className="col-md-12 col-lg-12 mb-3 h-100 ">
				<div className="page-title">
					<h5 className="d-flex">
						{AVAILABILITY_REQUESTS}
						<MlxPopover data={{title:AVAILABILITY_REQUESTS, body:AVAILABILITY_REQUESTS_HELP}}/>
					</h5>
				</div>

				<div className="card h-100 overflow-auto">
					<div className="card-header flex-grow-0">
						<div className="d-flex align-items-center">
							<div className="flex-grow-1 py-2">
								{showTitle &&
									<h4 className="mb-0 title">{AVAILABILITY_REQUESTS}</h4>
								}
							</div>
							<div className="flex-grow-0 me-2 ms-1">
								<div className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}>
									<span className="fa fa-search form-control-feedback">
										<FaSearch />{' '}
									</span>
									<DebounceInput
										onFocusCapture={() => {breakPoint >= width && setShowTitle(false)}}
										onBlurCapture={(event: any) => {                                                
											if(breakPoint >= width && event.target.value.length === 0) {
												setShowTitle(true)
											}
										}}
										className="form-control"
										placeholder="Search"
										minLength={0}
										debounceTimeout={500}
										onChange={() => {}}
										data-test-id={'availability-search-input'}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="card-body flex-grow-1 overflow-auto">
						<div className="h-100 d-flex flex-column p-2">
							<AllAvailabilityDataGrid
								queryClient={queryClient}
								tableData={allAvailabilityList}
								setMode={setMode}
								setToastMessage={setToastMessage}
								setShowSideBar={setShowSideBar}
								showSideBar={showSideBar}
								onAvailabilityDeleteClick={onAvailabilityDeleteClick}
								setAvailabilityType={setAvailabilityType}
								dataTableEvent={dataTableEvent}
								setDataTableEvent={setDataTableEvent}
								paginationData={paginationData}
								loading={availabilityListData.isFetching}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default withSidebar(AvailabilityList)
