import React, { SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { ACTIVATE_ITEM, DEACTIVATE_ITEM, DELETE_WARNING, ACTIVATE, DEACTIVATE, EDIT, DELETE, MOVE, DUPLICATE, COPY } from "../../../../constants/strings";
import { AxiosResponse } from "axios";
import { checkPermission, getErrorMessage, getHref } from "../../../../helpers/utils";
import { CSS } from '@dnd-kit/utilities';
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import { getMenuItemEditData, cloneMenuItem, getAsyncSingleMenuItem } from "../../../../helpers/menuManagementHelper";
import { Menu, MenuItem, SubCategory, Payload, SingleMenuItem } from "../../../../constants/staticTypes";
import { FnBCtx } from '../../../../context/MoveItemCtxProvider';
import { AlertVariant, PermissionKeys, SubPermissionKeys, WarningCallback } from "../../../../constants/constants";
import { QueryClient, UseMutationResult } from "react-query";
import { useSortable } from "@dnd-kit/sortable";
import Dropdown from 'react-bootstrap/Dropdown';
import placeholderImage from "../../../../assets/images/menu/540x360.png";
import useMutationHook from '../../../../hooks/useMutationHook';
import { JTranslation, TranslationContext, jTranslationText } from '../../../../helpers/jTranslate';
import MlxTooltip from '../../../common/MlxTooltip';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';

type Props = {
    item: MenuItem,
    onActiveInactiveToggle: (id: string, message: string, callbackType: SetStateAction<WarningCallback | undefined>) => void
    onSuccess: (message: string) => void,
    queryClient: QueryClient,
    selectedItem: MenuItem | undefined,
    setAddItem: React.Dispatch<React.SetStateAction<boolean>>,
    setCallbackType: React.Dispatch<React.SetStateAction<WarningCallback | undefined>>,
    setDeleteItem: React.Dispatch<React.SetStateAction<string | undefined>>,
    setEditItem: React.Dispatch<React.SetStateAction<Menu | SubCategory | MenuItem | undefined>>,
    setSelectedItem: React.Dispatch<React.SetStateAction<MenuItem | undefined>>,
    setWarning: React.Dispatch<React.SetStateAction<boolean>>,
    setWarningMessage: React.Dispatch<React.SetStateAction<string>>,
    showAddItem: boolean,
    upsertMenu: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    setVariant: React.Dispatch<React.SetStateAction<string>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
    setShow: React.Dispatch<React.SetStateAction<boolean>>,
}

function ItemComponent({
    item,
    setSelectedItem,
    selectedItem,
    setEditItem,
    setAddItem,
    showAddItem,
    queryClient,
    setCallbackType,
    setDeleteItem,
    setWarningMessage,
    setWarning,
    onActiveInactiveToggle,
    onSuccess,
    upsertMenu,
    setVariant,
    setErrorMessage,
    setShow
}: Props) {
    const itemCard = useRef(null)
    // CONTEXT VARIABLE
    const { moveItemSideBar, setMoveItemSideBar, setItemToBeMoved, setItemToBeMovedDetails, setMoveType } =
        useContext(FnBCtx)
    const translationContext = useContext(TranslationContext)
    const { targetLanguage } = translationContext
    const { trackEvent } = useTrackEvent();

    const [translationText, setTranslatedText] = useState<{
        deActivate: string,
        activate: string,
        edit: string,
        delete: string,
    }>({
        deActivate: DEACTIVATE,
        activate: ACTIVATE,
        edit: EDIT,
        delete: DELETE,
    });

    const getSingleMenuItemMutation = useMutationHook(queryClient, true)

    const getAsyncCustomMenuDetails = (editItem: MenuItem) => {
        const param: SingleMenuItem = { id: editItem?.id as string }
        getAsyncSingleMenuItem(param, getSingleMenuItemMutation)
            .then((response: any) => {
                onSingleMenuItemSuccess(response?.data?.data)
            })
            .catch((error) => {
                onError(getErrorMessage(error), AlertVariant.ERROR)
            })
    }

    const onSingleMenuItemSuccess = (data: MenuItem) => {
        setEditItem(getMenuItemEditData(data))
        setAddItem(!showAddItem)
    }

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition } = useSortable({ id: item.id as string });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "pan-y"
    };

    // on api error
    const onError = (message: string, variant: string) => {
        setVariant(variant);
        setErrorMessage(message);
        setShow(true);
    }

    const cloneItemApiCall = (itemMenuId: string) => {
        cloneMenuItem(upsertMenu, {
            itemMenuId: itemMenuId,
        }, onSuccess, onError);
    }

    // Translate on load and language switch
    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                const translations = await Promise.all([
                    jTranslationText({ text: DEACTIVATE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: ACTIVATE, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
                    jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
                ])
                setTranslatedText({
                    deActivate: translations[0] ?? DEACTIVATE,
                    activate: translations[1] ?? ACTIVATE,
                    edit: translations[2] ?? EDIT,
                    delete: translations[3] ?? DELETE,
                })
            } catch {
                setTranslatedText({
                    deActivate: DEACTIVATE,
                    activate: ACTIVATE,
                    edit: EDIT,
                    delete: DELETE,
                })
            }
        }
        fetchTranslation()
    }, [targetLanguage])

    return <div className="col-12 col-sm-4 col-md-3 col-lg-2" key={item.id} onClick={() => setSelectedItem(item)} ref={setNodeRef} style={style}
        {...attributes} {...listeners}>
        <div
            ref={itemCard}
            // onMouseEnter={() => {
            //     // @ts-ignore
            //     itemCard.current.click()
            // }}
            className={"viewMenubox-container mb-3 item " + (selectedItem?.id === item.id && "active ") + (!item.isActive && " in-active")}
        >
            <div className="menubox-img-contanier category custom-height">
                <Dropdown className="move-btn-outer">
                    <Dropdown.Toggle variant="success" id="dropdown-basic" >
                        <span className="move-round  d-flex align-items-center justify-content-center bg-white rounded-circle shadow link-secondary">
                            <i className="ri-more-2-fill"></i>
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='zzz'>
                        <Dropdown.Item className=" d-flex move-list" href="#/action-1" data-testid="move-to"
                            onClick={() => {
                                setItemToBeMoved(item.id as string)
                                setItemToBeMovedDetails(item)
                                setMoveType("item")
                                setMoveItemSideBar(!moveItemSideBar)
                            }}
                        ><i className="ri-drag-move-2-line"></i><JTranslation typeCase="pascal" text={MOVE} />
                        </Dropdown.Item>
                        <Dropdown.Item className=" d-flex move-list" href="#/action-1" data-testid="copy-to"
                            onClick={() => {
                                setItemToBeMoved(item.id as string)
                                setItemToBeMovedDetails(item)
                                setMoveType("copy-item-to")
                                setMoveItemSideBar(!moveItemSideBar)
                            }}
                        ><i className="ri-drag-move-2-line"></i><JTranslation typeCase="pascal" text={COPY} />
                        </Dropdown.Item>
                        <Dropdown.Item className=" d-flex move-list" href="#/action-1"
                            onClick={() => cloneItemApiCall(item.id as string)
                            }
                        ><i className="ri-file-copy-fill"></i><JTranslation typeCase="pascal" text={DUPLICATE} />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {/* <img src={item.signedPhotoKeys && item.signedPhotoKeys.length > 0 ? item.signedPhotoKeys[0] : placeholderImage} alt="menu item" className="img-fluid" /> */}
                <img
                    src={Array.isArray(item?.photoThumbnails) && item.photoThumbnails.length > 0
                        ? item.photoThumbnails[0]
                        : placeholderImage}
                    onError={(event) => {
                        const imgElement = event.currentTarget;
                        const fallbackAttempts = imgElement.getAttribute('data-fallback-attempts');

                        // Handle first error (primary image failed)
                        if (!fallbackAttempts) {
                            // Try signedPhotoKeys as fallback if available
                            const fallbackImage = Array.isArray(item?.signedPhotoKeys) && item.signedPhotoKeys.length > 0
                                ? item.signedPhotoKeys[0]
                                : placeholderImage;

                            // Apply fallback image and set attribute to mark the attempt
                            imgElement.src = fallbackImage;
                            imgElement.setAttribute('data-fallback-attempts', '1'); // Mark the first attempt
                        }
                        // Handle second error (secondary image failed)
                        else if (fallbackAttempts === '1') {
                            // Final fallback to placeholderImage
                            imgElement.src = placeholderImage;
                            imgElement.setAttribute('data-fallback-attempts', '2'); // Mark the final attempt
                        }
                    }}
                    alt="menu item"
                    className="img-fluid"
                />

            </div>
            <div className="menubox-toolbox">
                <MlxTooltip target=".menu-title">
                    <div className="menu-title" data-pr-tooltip={item.itemName} data-pr-position="bottom">
                        {item.itemName}
                    </div>
                </MlxTooltip>
            </div>

            <div className="menubox-toolbox tools">
                {item.isActive ?
                    <FaToggleOn title={translationText.deActivate} data-testid="item-toggle-on" className={"view " +
                        (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS) &&
                            " disabled")} onClick={() => {
                                // Google Analytics
                                trackEvent({
                                    eventName: "button_click_F&B_deactivate_item",
                                    value: {
                                        page_location: getHref(),

                                    },
                                });
                                onActiveInactiveToggle(item.id as string, DEACTIVATE_ITEM, WarningCallback.ACTIVATE_DEACTIVATE_ITEM)
                            }
                            } /> :
                    <FaToggleOff title={translationText.activate} data-testid="item-toggle-off" className={"view " +
                        (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.STATUS) &&
                            " disabled")} onClick={() => {
                                // Google Analytics
                                trackEvent({
                                    eventName: "button_click_F&B_activate_item",
                                    value: {
                                        page_location: getHref(),

                                    },
                                });
                                onActiveInactiveToggle(item.id as string, ACTIVATE_ITEM, WarningCallback.ACTIVATE_DEACTIVATE_ITEM)
                            }
                            } />}
                <i title={translationText.edit} data-testid="item-edit" className={"edit ri-pencil-line " +
                    (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.EDIT) &&
                        " disabled")} onClick={() => {
                            if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.EDIT)) {
                                // Google Analytics
                                trackEvent({
                                    eventName: "button_click_F&B_edit_item",
                                    value: {
                                        page_location: getHref(),

                                    },
                                });
                                getAsyncCustomMenuDetails(item)
                            }
                        }}></i>
                <i title={translationText.delete} data-testid="item-delete" className={"ri-delete-bin-line delete " +
                    (!checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.DELETE) &&
                        " disabled")} onClick={() => {
                            if (checkPermission(queryClient, PermissionKeys.MANAGE_MENU, SubPermissionKeys.DELETE)) {
                                // Google Analytics
                                trackEvent({
                                    eventName: "button_click_F&B_delete_item",
                                    value: {
                                        page_location: getHref(),

                                    },
                                });
                                setCallbackType(WarningCallback.DEL_ITEM);
                                setDeleteItem(item.id);
                                setWarningMessage(DELETE_WARNING);
                                setWarning(true);
                            }
                        }}></i>
            </div>

        </div>
    </div>
}

export default ItemComponent;