import { useState, useCallback, ChangeEvent } from 'react'
import { AxiosResponse } from 'axios'
import { AlertVariant, FileType, IMAGE_TYPE } from '../../../../constants/constants'
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import {
	DELETE_WARNING,
	UPLOAD_PHOTO,
	UPLOAD_PHOTO_MESSAGE,
	UPLOAD_VIDEO,
	UPLOAD_VIDEO_TYPE_ERROR,
	AI_IMAGE_GENERATOR,
	UPLOAD_PHOTO_INFO,
	UPLOAD_PHOTO_ERROR,
} from '../../../../constants/strings'
import { checkIfHeicHeif, getVideoSignedUrl, uploadFile, uploadFileUsingSignedUrl } from '../../../../helpers/fileUploadHelper'
import { CropType, Dimension, Payload, UploadedAiImageInfo } from '../../../../constants/staticTypes'
import { UseMutationResult } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import AiImageGenerator from '../../../ai_image_generator/AiImageGenerator'
import CommonModal from '../../../common_modal/CommonModal'
import ImageCropModal from './ImageCropModal'
import ImageThumbnail from '../draggable/ImageThumbnail'
import menuImg1 from '../../../../assets/images/menu/no_image_upload.png'
import VideoImg from '../../../../assets/images/menu/no_video_upload.png'
import WarningModal from '../../../warning_modal/WarningModal'
import { JTranslation } from '../../../../helpers/jTranslate'
import { replaceMessageValues } from '../../../../helpers/utils'

type Props = {
	displayToast: (message: string, variant: string) => void
	uploadFileMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>
	onUploadSuccess: (key: string, fileUrl: string, fileType: FileType) => void
	onUploadError: (fileType: FileType) => void
	images: string[]
	videos: string[]
	onDeleteFile: (fileType: FileType, index: number) => void
	setImages: React.Dispatch<React.SetStateAction<string[]>>
	formData: any
	setFormData: React.Dispatch<React.SetStateAction<any>>
	disableVideoUpload?: boolean
	divClass?: string
	parent?: 'beverage' | 'food' | 'other'
	validateImageDimension?: Dimension
	cropType?: CropType
}

function FileUpload({
	displayToast,
	uploadFileMutation,
	onUploadSuccess,
	onUploadError,
	setFormData,
	formData,
	images,
	videos,
	onDeleteFile,
	setImages,
	disableVideoUpload = false,
	divClass = '',
	parent,
	validateImageDimension,
	cropType = 'FREEFORM',
}: Readonly<Props>) {
	const [warning, setWarning] = useState(false)
	const [deleteData, setIndex] = useState({ type: FileType.IMAGE, index: 0 })
	const [imageCropSrc, setImageCrop] = useState<string | ArrayBuffer | null>(null)
	const [aiImageModal, setAiImageModal] = useState(false)

	// drag n drop sensors
	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 2,
			},
		})
	)

	const saveImages = useCallback(({ imageKeys, signedImageUrls }: UploadedAiImageInfo) => {
		if (formData.photoKeys) {
			setFormData((prevData: any) => {
				return {
					...prevData,
					photoKeys: [...prevData.photoKeys, ...imageKeys],
				}
			})
		}

		if (formData.imageKeys) {
			setFormData((prevData: any) => {
				return {
					...prevData,
					imageKeys: [...prevData.imageKeys, ...imageKeys],
					signedImageUrls: [...prevData.signedImageUrls, ...signedImageUrls],
				}
			})
		}

		setImages((prevImages: string[]) => {
			return [...prevImages, ...signedImageUrls]
		})
	}, [])

	// drag end handler
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event
		const oldIndex = active?.data.current && active.data.current.sortable.index
		const newIndex = over?.data.current && over?.data.current.sortable.index
		const newList = arrayMove(images, oldIndex, newIndex)
		const photoKeys = [...formData.photoKeys]
		const newPhotoKeysList = arrayMove(photoKeys, oldIndex, newIndex)
		setImages([...newList])
		setFormData({ ...formData, photoKeys: [...newPhotoKeysList] })
	}

	// upload video on fetch of signed url
	const onVideoUrlSuccess = (
		key: string,
		fileType: FileType,
		url: string,
		file: File,
		fields: { [key: string]: string }
	) => {
		uploadFileUsingSignedUrl(url, key, file, uploadFileMutation, onUploadSuccess, onUploadError, fileType, fields)
	}

	// validate upload image dimensions
	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0]
		if (!file) {
			return // Handle no file selected case
		}

		const reader = new FileReader()
		reader.onload = (event) => {
			if (event.target?.result && typeof event.target?.result === 'string') {
				const img = new Image()
				img.onload = () => {
					const width = img.naturalWidth
					const height = img.naturalHeight
					if (
						validateImageDimension?.width &&
						validateImageDimension?.height &&
						(width < validateImageDimension.width || height < validateImageDimension.height)
					) {
						displayToast(
							replaceMessageValues(UPLOAD_PHOTO_ERROR, [
								validateImageDimension?.width,
								validateImageDimension?.height,
							]),
							AlertVariant.ERROR
						)
						e.target.value = ''
					} else {
						uploadFile(
							FileType.IMAGE,
							e,
							uploadFileMutation,
							onUploadSuccess,
							onUploadError,
							displayToast,
							setImageCrop
						)
					}
				}
				img.src = event.target.result
			}
		}
		reader.readAsDataURL(file)
	}

	return (
		<>
			<ImageCropModal
				image={imageCropSrc as string}
				show={imageCropSrc !== null}
				setImageCrop={setImageCrop}
				mutation={uploadFileMutation}
				onSuccess={onUploadSuccess}
				onError={onUploadError}
				displayToast={displayToast}
				cropType={cropType}
			/>
			<WarningModal
				show={warning}
				onHide={() => setWarning(false)}
				title={DELETE_WARNING}
				callback={() => {
					onDeleteFile(deleteData.type, deleteData.index)
					setWarning(false)
				}}
			/>
			<CommonModal
				show={aiImageModal}
				modalContent={<AiImageGenerator callBack={saveImages} type="otherImage" cropType={cropType} />}
				onHide={setAiImageModal}
				title={AI_IMAGE_GENERATOR}
				hideFooter
			/>
			<div className={divClass}>
				{' '}
				{/* file upload div column start */}
				<div className="row mb-3">
					{' '}
					{/* image upload div row start */}
					<div className="mb-3 col-md-12  col-lg-12  ">
						<div className="row img-flex">
							{/* <!---image----> */}
							<DndContext
								sensors={sensors}
								collisionDetection={closestCenter}
								onDragEnd={(e) => handleDragEnd(e)}
							>
								<SortableContext items={images.map((image) => image)} strategy={rectSortingStrategy}>
									{images.map((image, index) => {
										return (
											<ImageThumbnail
												image={image}
												index={index}
												key={uuidv4()}
												setIndex={setIndex}
												setWarning={setWarning}
											/>
										)
									})}
								</SortableContext>
							</DndContext>
							{images.length === 0 && (
								<div className=" col-6 col-md-4 col-lg-6 col-xl-6 mb-2">
									<div className="no-image-avathar img-thumb-main">
										<div className="view-img-preview">
											<img src={menuImg1} className="img-fluid rounded-1" alt=" " />
										</div>
										<div className="preview-tool text-end">
											<i className="ri-delete-bin-6-line delete"></i>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="mb-3 col-md-12  col-lg-12 d-flex flex-column upload-c">
						<label
							htmlFor="image-upload"
							className="custom-file-upload btn  btn-custom-primary-outline w-100 "
						>
							<i className="ri-upload-line"></i> <JTranslation typeCase="pascal" text={UPLOAD_PHOTO} />
						</label>
						<input
							data-testid="image-upload"
							id="image-upload"
							type="file"
							accept="image/*,.heic,.heif"
							autoComplete="off"
							onChange={async (event) => {
								const updatedEvent = await checkIfHeicHeif(event) 
								if (validateImageDimension) {
									handleFileChange(updatedEvent)
								} else {
									uploadFile(
										FileType.IMAGE,
										updatedEvent,
										uploadFileMutation,
										onUploadSuccess,
										onUploadError,
										displayToast,
										setImageCrop
									)
								}
							}}
						/>
						<div className="mt-2 small text-secondary">
							<JTranslation
								typeCase="capitalize"
								text={
									validateImageDimension?.width && validateImageDimension?.height
										? replaceMessageValues(UPLOAD_PHOTO_INFO, [
												validateImageDimension?.width,
												validateImageDimension?.height,
										  ])
										: UPLOAD_PHOTO_MESSAGE
								}
							/>
						</div>

						{parent !== 'beverage' && (
							<div className="col-12 mt-3">
								<label
									htmlFor="image-generate"
									className="custom-file-upload btn  btn-custom-primary w-100"
									onClick={() => setAiImageModal(true)}
								>
									 <i className="ri-ai-generate"></i>{' '}
									<JTranslation typeCase="pascal" text={AI_IMAGE_GENERATOR} />
								</label>
							</div>
						)}
					</div>
				</div>{' '}
				{/* image upload div row end */}
				{!disableVideoUpload && (
					<>
						<div className="row mb-3">
							{' '}
							{/* video upload div row start */}
							<div className="mb-3 col-md-12  col-lg-12  img-flex">
								<div className="row">
									{videos.map((video, index) => {
										return (
											<div className=" col-6 col-md-4 col-lg-6 col-xl-6 mb-2" key={uuidv4()}>
												<div className="video-thumb-main ">
													<div className="video-preview">
														<video src={video} controls className="img-fluid" />
													</div>
													<div className="preview-tool text-end">
														<i
															className="ri-delete-bin-6-line delete"
															onClick={() => {
																setIndex({
																	type: FileType.VIDEO,
																	index,
																})
																setWarning(true)
															}}
														></i>
													</div>
												</div>
											</div>
										)
									})}
									{videos.length === 0 && (
										<div className=" col-6 col-md-4 col-lg-6 col-xl-6 mb-2">
											<div className="no-image-avathar video-thumb-main ">
												<div className="video-preview">
													<img src={VideoImg} className="img-fluid" alt="" />
												</div>
												<div className="preview-tool text-end">
													<i className="ri-delete-bin-6-line delete"></i>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
							<div className="mb-3 col-md-12  col-lg-12 d-flex flex-column upload-c">
								<label
									htmlFor="video-upload"
									className="custom-file-upload btn  btn-custom-primary-outline w-100 "
								>
									<i className="ri-upload-line"></i>{' '}
									<JTranslation typeCase="pascal" text={UPLOAD_VIDEO} />
								</label>
								<input
									data-testid="video-upload"
									id="video-upload"
									type="file"
									accept="video/*"
									autoComplete="off"
									onChange={(e) => {
										if (e.target.files && e.target.files?.length > 0) {
											if (e.target.files[0].type.indexOf(FileType.VIDEO) !== -1) {
												getVideoSignedUrl(
													e.target.files[0],
													uploadFileMutation,
													onVideoUrlSuccess,
													onUploadError
												)
												e.target.value = ''
											} else {
												displayToast(UPLOAD_VIDEO_TYPE_ERROR, AlertVariant.ERROR)
											}
										}
									}}
								/>
							</div>
						</div>{' '}
						{/* video upload div row end */}
					</>
				)}
			</div>{' '}
			{/* file upload div column end */}
		</>
	)
}

export default FileUpload
