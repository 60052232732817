import { helpApi } from "../constants/apiEndPoints";
import { HttpMethods } from "../constants/constants";
import HttpServiceHelper from "./httpServiceHelper";
import * as emoji from "emoji-dictionary";

export type HelpSections = {
  title: string;
  content: string;
};

export type HelpItem = {
  id: string;
  title: string;
  slug: string;
  content: string;
  status: string;
  visibility: string[];
  sections: HelpSections[];
  tags: string[];
};

export type ImageComponentProps = {
  src: string;
  alt: string;
  width?: string;
  style?: React.CSSProperties;
};

export const getHelpList = (search: string, limit: number, lastKey: string) => {
  return HttpServiceHelper({
    url: helpApi + '?limit=' + limit,
    method: HttpMethods.GET,
  });
};

export const unescapeMarkdown = (escapedString: any) => {
  return escapedString
    ?.replace(/\\n/g, "\n") // Convert \n back to actual newlines
    ?.replace(/\\\\/g, "\\") // Convert \\ back to single \
    ?.replace(/\\"/g, '"') // Convert \" back to "
    ?.replace(/\\t/g, "\t") // Convert \t back to tabs
    ?.replace(
      /:([a-zA-Z0-9-_+]+):/g,
      (match: string, emojiName: string): string => {
        const unicodeEmoji = emoji.getUnicode(emojiName);
        return unicodeEmoji || match;
      },
    );
};
